import React, { useEffect, useState } from 'react';
import { useToaster } from '@frontend-modules/ui-kit';
import { useAppSelector } from 'store/store';

import { getAccessToken } from 'utils/token';

import { ENotificationEvents, MAX_RECONNECT_ATTEMPTS, NOTIFICATION_PATH } from './AppSocketNotifications.config';
import { TNotificationData } from './AppSocketNotifications.types';
import { createSocket, Socket } from './AppSocketNotifications.utils';

export const AppSocketNotifications: React.FC = () => {
    const { id: uuid } = useAppSelector((state) => state?.user?.info) || {};
    const [notificationSocket, setNotificationSocket] = useState<Socket | null>(null);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);

    const { showToast } = useToaster({
        showDuration: 3,
    });

    const addSocketEventHandlers = (socket: Socket) => {
        socket.on(ENotificationEvents.TOAST_NOTIFICATION, (data: TNotificationData) => {
            const toastConfig = {
                message: data.title,
                description: data.description,
            };
            showToast(toastConfig, data.status);
        });

        socket.on(ENotificationEvents.DISCONNECTED, () => {
            reconnectSocket();
        });
        socket.on(ENotificationEvents.CONNECT_ERROR, (err) => {
            if (reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
                console.error('WS connection error: ', err);
                return;
            }
            reconnectSocket();
        });
    };
    const removeSocketEventHandlers = () => {
        if (notificationSocket) {
            notificationSocket.off(ENotificationEvents.TOAST_NOTIFICATION);
            notificationSocket.off(ENotificationEvents.DISCONNECTED);
            notificationSocket.off(ENotificationEvents.CONNECT_ERROR);
        }
    };

    const connectSocket = () => {
        const token = getAccessToken();

        if (notificationSocket && notificationSocket.connected) {
            return;
        }

        const socket = createSocket(NOTIFICATION_PATH, { token });
        setNotificationSocket(socket);

        addSocketEventHandlers(socket);
        socket.connect();
        setReconnectAttempts(0);
    };
    const disconnectSocket = () => {
        if (notificationSocket) {
            removeSocketEventHandlers();
            notificationSocket.disconnect();
            setNotificationSocket(null);
        }
    };
    const reconnectSocket = () => {
        if (notificationSocket) {
            setReconnectAttempts((prev) => prev + 1);
            disconnectSocket();
            connectSocket();
        }
    };

    useEffect(() => {
        if (uuid) {
            disconnectSocket();
            connectSocket();
        }

        return () => disconnectSocket();
    }, [uuid]);

    return null;
};
