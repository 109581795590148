import React from 'react';
import { useHistory } from 'react-router-dom';
import { ErrorsCaptureWrapperUi } from '@frontend-modules/error-handler';
import { AppText } from '@frontend-modules/ui-kit';
import { TTextButtonProps } from '@fsd-shared/ui-kit/TextButton/TextButton.types';
import { E_COLORS } from 'src-new/constants';

import './TextButton.style.scss';

export const TextButton: React.FC<TTextButtonProps> = (props) => {
    const {
        label,
        handleClick,
        wrapperClassName,
        textStyle = 'DesktopFontBody',
        color = E_COLORS.firm_blue_color,
        disabled = false,
    } = props;
    const history = useHistory();

    const handleClickLocal = () => {
        try {
            handleClick();
        } catch (err) {
            ErrorsCaptureWrapperUi({
                error: err,
                componentName: 'lms TextButton',
                MethodCatchOn: 'handleClick()',
                PathCatchOn: history.location.pathname,
            });
        }
    };
    return (
        <div
            className={`text-button ${wrapperClassName ?? ''} ${disabled ? 'disabled' : ''}`}
            onClick={handleClickLocal}
        >
            <AppText text={label} textStyle={textStyle} color={disabled ? E_COLORS.light_grey_color_1 : color} />
        </div>
    );
};
