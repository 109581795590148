import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppFontIcon, AppText } from '@frontend-modules/ui-kit';
import {
    DropArea,
    QuestionInfoModal,
    TAreaName,
    TDroppedData,
    TModalButtonType,
    TQuestionsPaginate,
    TSearchResultBlock,
} from 'src-new/components/lesson-task';
import { E_COLORS } from 'src-new/constants';
import { useGetQuestionModalControls, useQuestionsDrop, useQuestionsMove } from 'src-new/hooks';
import { getRightTaskTitle } from 'src-new/utils';
import { getQuestionDetailsAction, setOpenedQuestionIdAction, setSelectedBlockAction } from 'store/actions/lessonTask';
import { useAppSelector } from 'store/store';
import { TAssignedQuestion, TSearchBlock, TSingleQuestion } from 'types/lessonTask';

import { initAssignedQuestion } from './SearchResult.constants';

import './style.scss';

export const SearchResult: React.FC<TSearchResultBlock> = (props) => {
    const dispatch = useDispatch();
    const { selectedQuestions, searchQuestions, activeQuestionNumber, isTrainer } = props;
    const [rightBlock, setRightBlock] = useState<TAssignedQuestion>(initAssignedQuestion);
    const [leftBlock, setLeftBlock] = useState<TAssignedQuestion>(initAssignedQuestion);

    const selectedQuestionsCount = useMemo(() => {
        const singleCount = rightBlock?.singleQuestionsList?.length ?? 0;
        let blockCount = 0;
        rightBlock?.searchBlocksList?.forEach((item) => {
            const blockQuestionsIds = item?.questionsContentList
                ?.map(({ questionContent }) => questionContent?.id)
                .filter(Boolean);
            const existingExcludedIds = item?.filters?.excludeIds?.filter((item) => blockQuestionsIds.includes(item));
            const excludedCount = existingExcludedIds?.length ?? 0;

            blockCount += item?.questionsContentList.length - excludedCount;
        });
        return singleCount + blockCount;
    }, [rightBlock]);

    const { openedQuestionId, selectedBlock } = useAppSelector((store) => store.lessonTask);

    useEffect(() => {
        setRightBlock(selectedQuestions ?? initAssignedQuestion);
    }, [selectedQuestions]);
    useEffect(() => {
        setLeftBlock(
            {
                searchBlocksList: searchQuestions,
                singleQuestionsList: [],
            } ?? initAssignedQuestion,
        );
    }, [searchQuestions]);

    const {
        questionsIdsToPaginate,
        canPaginateRight,
        canPaginateLeft,
        hasRemoveButton,
        hasExcludeButton,
        hasIncludeButton,
        hasAddButton,
    } = useGetQuestionModalControls({ rightBlock, leftBlock, openedQuestionId });
    const { handleDropBlock, handleDropSingle, handleDropSingleBlock } = useQuestionsDrop({
        rightBlock,
        leftBlock,
        activeQuestionNumber,
    });
    const { onBlockQuestionMove, onAddSingleQuestion, onRemoveSingleQuestion } = useQuestionsMove({
        rightBlock,
        leftBlock,
        activeQuestionNumber,
    });

    const handleQuestionsDrop = (droppedData: TDroppedData) => {
        const { areaName, type, data } = droppedData;

        if (type === 'block') {
            handleDropBlock(areaName, data as TSearchBlock);
        } else if (type === 'single') {
            handleDropSingle(areaName, data as TSingleQuestion);
        } else if (type === 'block-single') {
            handleDropSingleBlock(data as TSingleQuestion);
        }
    };

    const handleQuestionsMove = (id: number, type: TModalButtonType) => {
        const rightBlockSelected = rightBlock.searchBlocksList?.find(
            ({ questionsContentList }) =>
                !!questionsContentList.find(({ questionContent }) => questionContent?.id === id),
        );
        const leftBlockSelected = leftBlock.searchBlocksList?.find(
            ({ questionsContentList }) =>
                !!questionsContentList.find(({ questionContent }) => questionContent?.id === id),
        );
        const leftQuestionSelected = leftBlock.singleQuestionsList?.find(
            ({ questionContent }) => questionContent?.id === id,
        );
        const rightQuestionSelected = rightBlock.singleQuestionsList?.find(
            ({ questionContent }) => questionContent?.id === id,
        );

        const leftBlockSingleSelected = leftBlock.searchBlocksList
            ?.find(
                ({ questionsContentList }) =>
                    !!questionsContentList.find(({ questionContent }) => questionContent?.id === id),
            )
            ?.questionsContentList.find(({ questionContent }) => questionContent?.id === id);

        switch (type) {
            case 'include':
            case 'exclude':
                onBlockQuestionMove(id, type, rightBlockSelected, leftBlockSelected);
                break;
            case 'add':
                onAddSingleQuestion(leftBlockSingleSelected, leftQuestionSelected);
                break;
            case 'remove':
                onRemoveSingleQuestion(rightQuestionSelected);
                break;
        }
    };

    const handleQuestionsPaginate = (direction: TQuestionsPaginate) => {
        const index = questionsIdsToPaginate?.findIndex((id) => id === openedQuestionId);

        if (questionsIdsToPaginate && index !== undefined && index >= 0) {
            //@ts-ignore
            const nextQuestionId: number =
                direction === 'next' ? questionsIdsToPaginate[index + 1] : questionsIdsToPaginate[index - 1];
            dispatch(setOpenedQuestionIdAction(nextQuestionId));
            dispatch(getQuestionDetailsAction(nextQuestionId));
        }
    };
    const handleClickBlocksSetting = (block: TSearchBlock, areaName: TAreaName, index: number) => {
        const isNeedUpdate = selectedBlock?.number !== index || selectedBlock?.side !== areaName;

        if (isNeedUpdate) {
            dispatch(setSelectedBlockAction({ side: areaName, number: index, block }));
        } else {
            dispatch(setSelectedBlockAction(undefined));
        }
    };

    return (
        <div className={'search-result'}>
            <QuestionInfoModal
                canPaginateRight={canPaginateRight}
                canPaginateLeft={canPaginateLeft}
                canAdd={hasAddButton}
                canRemove={hasRemoveButton}
                canExclude={hasExcludeButton}
                canInclude={hasIncludeButton}
                handleModalButtonClick={handleQuestionsMove}
                handleQuestionsPaginate={handleQuestionsPaginate}
            />
            <div className={'search-result__container results'}>
                <div className={'search-result__container_title'}>
                    <AppText text={'Результаты'} />
                    <AppText
                        text={'Перетащите блок или отдельные вопросы вправо'}
                        color={E_COLORS.light_grey_color_2}
                        textStyle={'DesktopFootNotes'}
                    />
                </div>
                <DropArea
                    key={'left'}
                    questions={leftBlock}
                    areaName={'left'}
                    handleDropItem={handleQuestionsDrop}
                    selectedBlock={selectedBlock}
                    handleBlockSettingsClick={(block, number) => handleClickBlocksSetting(block, 'left', number)}
                    canDragQuestions
                />
            </div>
            <div className={'search-result__container selected'}>
                <div className={'search-result__container_title'}>
                    <div className={'title-with-icon'}>
                        <AppFontIcon icon={'check-l'} color={E_COLORS.mint_color} />
                        <AppText
                            text={
                                isTrainer
                                    ? 'Отбор в тренажер'
                                    : `Отбор ${getRightTaskTitle(activeQuestionNumber)} ${
                                          selectedQuestionsCount ? `(${selectedQuestionsCount}шт)` : ''
                                      }`
                            }
                        />
                    </div>
                    <AppText
                        className={'info'}
                        text={'*будет задан 1 случайный вопрос из списка'}
                        color={E_COLORS.light_grey_color_2}
                        textStyle={'DesktopFootNotes'}
                    />
                </div>
                <DropArea
                    key={'right'}
                    questions={rightBlock}
                    areaName={'right'}
                    handleDropItem={handleQuestionsDrop}
                    selectedBlock={selectedBlock}
                    handleBlockSettingsClick={(block, number) => handleClickBlocksSetting(block, 'right', number)}
                />
            </div>
        </div>
    );
};
