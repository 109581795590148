import React, { useMemo } from 'react';
import { useWindowResize } from 'hooks';
import { EmptyList } from 'src-new/components/new-subjects';
import { useGetSubjectsTreeData } from 'src-new/hooks';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';

import { CoursesColContentMemo } from './course-col-content/CourseColContent';
import { LessonsColContentMemo } from './lessons-col-content/LessonsColContent';
import { TopicsColContentMemo } from './topics-col-content/TopicsColContent';
import { TSubjectsTableDesktop } from './SubjectsTableContent.types';

import './style.scss';

export const SubjectsTableContent: React.FC<TSubjectsTableDesktop> = (params: TSubjectsTableDesktop) => {
    const isDesktop = !useWindowResize();
    // хук получает список учителей и студентов
    const { teachersList, noCourses } = useGetSubjectsTreeData();

    const {
        hasBooks,
        handleTopicSelect,
        handleLessonSelect,
        handleTeacherSelect,
        handleLibraryClick,
        handleAddLessonClick,
        handleEditLessonClick,
    } = params;

    const { selectedCourse, getCourseTreeStatus, getTopicsTreeStatus, getLessonsTreeStatus } = useAppSelector(
        (store) => store.newSubject,
    );

    const { isCurious } = selectedCourse;

    const isCourseListLoading = getCourseTreeStatus === FetchStatus.FETCHING;
    const isTopicListLoading = isCourseListLoading || getTopicsTreeStatus === FetchStatus.FETCHING;
    const isLessonListLoading =
        isCourseListLoading || isTopicListLoading || getLessonsTreeStatus === FetchStatus.FETCHING;

    const handleTeacherClick = (id: number) => {
        const selectedTeacher = teachersList?.find((item) => item.id === id);

        if (selectedTeacher) {
            handleTeacherSelect(selectedTeacher);
        }
    };
    const handleStudentClick = (id: number) => {
        window.open(`/profile/student/${id}/homeworks`);
    };

    const TopicsColContent = useMemo(() => {
        return (
            <TopicsColContentMemo
                isTopicListLoading={isTopicListLoading}
                isCurious={!!isCurious}
                hasBooks={hasBooks}
                isDesktop={isDesktop}
                courseTeachers={teachersList}
                handleTeacherClick={handleTeacherClick}
                handleStudentClick={handleStudentClick}
                handleLibraryClick={handleLibraryClick}
                handleTopicSelect={handleTopicSelect}
                // В мобильной версии в темы встраивается соответствующий список уроков
                LessonsColContent={
                    <LessonsColContentMemo
                        isLessonListLoading={isLessonListLoading}
                        isCurious={!!isCurious}
                        handleLessonSelect={handleLessonSelect}
                        handleAddLessonClick={handleAddLessonClick}
                        handleEditLessonClick={handleEditLessonClick}
                    />
                }
            />
        );
    }, [isTopicListLoading, teachersList, isDesktop]);

    return noCourses ? (
        <EmptyList />
    ) : (
        <div className={`subjects-table-content ${isDesktop ? '' : 'mobile'}`} data-onboarding="subjectsContainer">
            <CoursesColContentMemo
                isCourseListLoading={isCourseListLoading}
                MobileContent={TopicsColContent}
                isDesktop={isDesktop}
            />
            {/*В десктопной версии темы и уроки выводятся как самостоятельные элементы, в мобилке - вложены в курсы*/}
            {isDesktop && (
                <>
                    {TopicsColContent}
                    <LessonsColContentMemo
                        isLessonListLoading={isLessonListLoading}
                        isCurious={!!isCurious}
                        handleLessonSelect={handleLessonSelect}
                        handleAddLessonClick={handleAddLessonClick}
                        handleEditLessonClick={handleEditLessonClick}
                    />
                </>
            )}
        </div>
    );
};
