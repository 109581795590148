import React, { useMemo } from 'react';
import { AppLoader } from '@frontend-modules/ui-kit';
import { AppButtonDropdown, AppScrollPaginateContainer, AppText } from 'src-new/ui';

import { TThemesColTabsProps } from './ThemesColTabs.types';

import './style.scss';

export const ThemesColTabs: React.FC<TThemesColTabsProps> = (props: TThemesColTabsProps) => {
    const {
        teachers,
        students,
        hasBooks,
        paginate,
        isStudentListLoading,
        handleTeacherClick,
        handleStudentClick,
        handleLibraryClick,
        handleStudentsListPaginate,
    } = props;

    const isStudents = !!students && !!students.length;

    const personsListNormalized = useMemo(() => {
        const list = isStudents ? students : teachers;

        return list?.length
            ? list?.map((item) => {
                  return {
                      label: (
                          <div className={'themes-col-tabs__item_person'}>
                              <div className={'themes-col-tabs__item_person-avatar'}>
                                  {!item.photoUrl ? (
                                      <img src={item.photoUrl} alt={'teachers-avatar'} />
                                  ) : (
                                      <div className={'empty-image'}>
                                          <AppText
                                              text={`${item.lastName?.[0] ?? ''}${item.firstName?.[0] ?? ''}`}
                                              fontStyle={'main'}
                                          />
                                      </div>
                                  )}
                              </div>
                              <AppText
                                  text={`${item.lastName} ${item.firstName} ${item.patronymic}`}
                                  fontStyle={'fontBody'}
                              />
                          </div>
                      ),
                      key: item.id,
                  };
              })
            : [];
    }, [students, teachers]);

    const onTeacherClick = (id: number) => {
        handleTeacherClick(id);
    };
    const onStudentClick = (id: number) => {
        handleStudentClick(id);
    };
    const onLibraryClick = () => {
        handleLibraryClick();
    };

    const TeachersBlock = (): JSX.Element => {
        switch (personsListNormalized.length) {
            case 1:
                return (
                    <div
                        className={'themes-col-tabs__item'}
                        data-onboarding="teacherButton"
                        onClick={() => onTeacherClick(personsListNormalized[0].key)}
                    >
                        <AppText text={'Учитель'} fontStyle={'H4'} />
                    </div>
                );
            case 0:
                return <></>;
            default:
                return (
                    <div data-onboarding="teacherButton" style={{ width: '100%' }}>
                        <AppButtonDropdown
                            options={personsListNormalized}
                            buttonText={'Учителя'}
                            onDropdownSelect={onTeacherClick}
                        />
                    </div>
                );
        }
    };

    return (
        <div className={'themes-col-tabs'}>
            {isStudents ? (
                <AppButtonDropdown
                    options={personsListNormalized}
                    buttonText={'Ученики'}
                    onDropdownSelect={onStudentClick}
                    DropdownContainer={(children) => (
                        <AppScrollPaginateContainer
                            customClassName={'app-button-dropdown__scrollable-container'}
                            needReset={false}
                            maxPage={paginate?.maxPage ?? 1}
                            maxHeight={300}
                            onScrollHandle={(currPage) => handleStudentsListPaginate?.(currPage)}
                        >
                            <>
                                {children}
                                {isStudentListLoading && (
                                    <div className={'themes-col-tabs__item_loader'}>
                                        <AppLoader />
                                    </div>
                                )}
                            </>
                        </AppScrollPaginateContainer>
                    )}
                />
            ) : (
                <TeachersBlock />
            )}
            {hasBooks && (
                <div className={'themes-col-tabs__item'} onClick={() => onLibraryClick()}>
                    <AppText text={'Библиотека'} fontStyle={'H4'} />
                </div>
            )}
        </div>
    );
};
