export const getUpdatingErrors = (error: string): string[] => {
    try {
        const errorData = JSON.parse(error) as {
            message: string;
            extra: any;
        };
        if (errorData.extra) {
            const validationErr = errorData.extra?.fields;

            if (typeof errorData.extra === 'string') {
                return [errorData.extra];
            }
            if (Array.isArray(validationErr)) {
                return validationErr;
            }
            return Object.values(validationErr).map((item) => {
                if (Array.isArray(item)) {
                    return item.join(', ');
                }
                return typeof item === 'string' ? item : JSON.stringify(item);
            });
        } else {
            return errorData.message ? [errorData.message] : ['Что-то пошло не так. Попробуйте еще раз!'];
        }
    } catch (e) {
        return [error];
    }
};
