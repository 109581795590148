import { convertError } from 'utils/convertError';
type TError = { message?: string };

export const getFieldsError = (error: TError): { [key: string]: string[] } => {
    const errorData = convertError(error as TError);

    if (typeof errorData === 'string') {
        return { message: [errorData] };
    }

    const extraFields = errorData.extra?.fields;

    if (extraFields) {
        const result: { [key: string]: string[] } = {};

        // Проверяем, является ли extra.fields массивом строк
        if (isArrayOfStrings(extraFields)) {
            result['message'] = extraFields as string[];
        } else {
            Object.entries(extraFields as { [key: string]: string | string[] }).forEach(([key, values]) => {
                if (typeof values === 'string') {
                    result[key] = [values];
                } else {
                    const isArray = isArrayOfStrings(values);

                    result[key] = isArray ? values : values.map((item) => JSON.stringify(item));
                }
            });
        }
        return result;
    }

    if (errorData.error) {
        return { message: [errorData.error] };
    }

    return { message: ['Что-то пошло не так, попробуйте снова'] };
};

const isArrayOfStrings = (data: any) => {
    return Array.isArray(data) && data.every((item) => typeof item === 'string');
};
