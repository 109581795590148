import React, { FC, useEffect } from 'react';
import { cn } from '@bem-react/classname';
import { AppButton, AppModal } from '@frontend-modules/ui-kit';

import { INotAllowedModalProps } from './NotAllowedModal.types';

import './NotAllowedModal.styles.scss';

const CnReAuthModal = cn('re-auth-modal');

/**
 * @param props
 */
const NotAllowedModal: FC<INotAllowedModalProps> = (props) => {
    const { isShowModal, setIsShowModal } = props;

    const onCloseModal = () => {
        setIsShowModal(false);
        // метод нативный так как компонент вне Router
        history.back();
    };

    const onReAuthClick = () => {
        window.open(`${process.env.REACT_APP_SSO_CLIENT}/?return=${window.location.href}`, '_self');
    };

    return (
        <AppModal
            style={{ marginTop: 200 }}
            isShow={isShowModal}
            setShow={onCloseModal}
            title={'Недостаточно прав'}
            description={'У текущего аккаунта недостаточно прав для использования сервиса'}
            className={CnReAuthModal()}
            footer={
                <div className={CnReAuthModal('footer')}>
                    <AppButton type={'outline'} label={'Назад'} onClick={onCloseModal} />
                    <AppButton type={'primary'} label={'Сменить аккаунт'} onClick={onReAuthClick} />
                </div>
            }
        />
    );
};

export default NotAllowedModal;
