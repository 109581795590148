import { timezoneError } from 'constants/errors';

import { ActionCreatorWithoutPayload, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { setError } from 'store/actions/error';
import { getCurrentTimeAction } from 'store/actions/user';

import { checkTimezone } from 'utils/date';

interface ErrorState {
    error?: string | null;
    descriptions?: string[];
    extra?: Record<string, number | unknown | string> | null;
    isFrontend?: boolean;
    isTimezoneError?: boolean;
    needHideButtons?: boolean;
    isBlockLesson?: boolean;
    needHideErrorIcon?: boolean;
    isOlBlocked?: boolean;
    disableCancelButton?: boolean;
    isLastAttemptDeliting?: boolean;
    isCourseDeleting?: boolean;
    customDescription?: string;
    createCertificate?: boolean;
    actionButtonClick?: () => void;
    cancelButtonClick?: () => void;
}

const initialState: ErrorState = {
    error: null,
};

interface ErrorPayload {
    message: string;
    extra: {
        isBlockLesson: boolean;
        needHideErrorIcon: boolean;
        needHideButtons?: boolean;
        isFrontend?: boolean;
        createCertificate?: boolean;
        isCourseDeleting?: boolean;
    } & Record<string, unknown>;
    actionButtonClick?: () => void;
    cancelButtonClick?: () => void;
}

export const errorSlice = createSlice<ErrorState, SliceCaseReducers<ErrorState>>({
    name: 'error',
    initialState,
    reducers: {
        ready: () => {
            return initialState;
        },
        error: (state, action: PayloadAction<ErrorPayload>) => {
            const {
                isBlockLesson,
                needHideErrorIcon,
                needHideButtons,
                isFrontend,
                createCertificate,
                isCourseDeleting,
                ...restExtraParams
            } = action.payload.extra;

            state.error = action.payload.message === 'Rejected' ? 'Ой, что-то пошло не так!' : action.payload.message;
            state.extra = restExtraParams;
            state.isFrontend = typeof isFrontend === 'boolean' ? isFrontend : true;
            state.isBlockLesson = isBlockLesson;
            state.needHideErrorIcon = needHideErrorIcon;
            state.actionButtonClick = action.payload.actionButtonClick;
            state.cancelButtonClick = action.payload.cancelButtonClick;
            state.needHideButtons = needHideButtons;
            state.createCertificate = createCertificate;
            state.isCourseDeleting = isCourseDeleting;
            if (action.payload.extra.isOlBlocked !== undefined)
                state.isOlBlocked = Boolean(action.payload.extra.isOlBlocked);
            if (action.payload.extra.disableCancelButton !== undefined)
                state.disableCancelButton = Boolean(action.payload.extra.disableCancelButton);
            if (action.payload.extra.isLastAttemptDeliting !== undefined)
                state.isLastAttemptDeliting = Boolean(action.payload.extra.isLastAttemptDeliting);
            if (action.payload.extra.customDescription !== undefined)
                state.customDescription = String(action.payload.extra.customDescription);
        },
        clear: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCurrentTimeAction.fulfilled, (state, { payload }) => {
            const isCorrectTimezone = checkTimezone(payload.time);

            if (!isCorrectTimezone) {
                state.error = timezoneError.error;
                state.descriptions = timezoneError.descriptions;
                state.isTimezoneError = true;
                state.actionButtonClick = () => undefined;
            }
        });
        builder.addCase(setError, (state, { payload }) => {
            state.error = payload;
            state.needHideButtons = true;
        });
    },
});

export const { ready, error } = errorSlice.actions;
export const clearError = errorSlice.actions.clear as ActionCreatorWithoutPayload;

export const errorReducer = errorSlice.reducer;
