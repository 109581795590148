import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getNoun } from '@frontend-modules/frontend-utils';
import { TARIFF_CURIOUS_TITLE } from 'App/constants';
import { useRole } from 'hooks';
import {
    NewSubjectsCardMemo,
    SubjectsElementsSkeleton,
    TCourseColContent,
    TCourseListItem,
} from 'src-new/components/new-subjects';
import { EMPTY_ARRAY_10 } from 'src-new/constants';
import { AppCollapse, AppText } from 'src-new/ui';
import { getUid } from 'src-new/utils';
import { setSelectedCourseAction } from 'store/actions/newSubjects';
import { useAppSelector } from 'store/store';
import { TCourseGroup, TGetCourseTreePayload, TSelectedCourse } from 'types/newSubjectsTypes';

export const CoursesColContent: React.FC<TCourseColContent> = (props) => {
    const { isStudent, isTeacher } = useRole();
    const { isDesktop, isCourseListLoading, MobileContent } = props;
    const { courseTree, selectedCourse } = useAppSelector((store) => store.newSubject);

    const dispatch = useDispatch();

    const handleCourseSelected = (course: TSelectedCourse) => {
        dispatch(setSelectedCourseAction(course));
    };
    // список курсов для студентов
    const getStudentCourseList = (
        courseTree: TGetCourseTreePayload,
        selectedCourse: TSelectedCourse,
    ): TCourseListItem[] => {
        const { common: commonCourses, choice: choiceCourses, additional: additionalCourses } = courseTree;
        const isCommonExist = commonCourses && !!commonCourses.length;
        const isChoiceExist = choiceCourses && !!choiceCourses.length;
        const isAdditionalExist = additionalCourses && !!additionalCourses.length;

        // Следующие три константы содержат в себе подготовленные списки курсов для встраивания в AppCollapse
        const commonCoursesList = isCommonExist
            ? commonCourses.map((course) => {
                  return {
                      header: (
                          <AppText text={`${course?.studentGroup?.title} — Основные курсы`} fontStyle={'footnotes'} />
                      ),
                      content: (
                          <div className={'subjects-table-content__col_wrapper-list'}>
                              {course?.studentGroup?.courseGroups?.map((value) => {
                                  const isCourseSelected = selectedCourse?.id === value.id;
                                  const needInsertContent = !isDesktop && isCourseSelected;

                                  return (
                                      <div key={`${value.id}${selectedCourse.id}`} data-onboarding="NewSubjectsCard">
                                          <NewSubjectsCardMemo
                                              title={value.course.title}
                                              additionalTitle={value.title}
                                              progress={value.progress}
                                              borderStyle={'gray'}
                                              isSelected={isCourseSelected}
                                              handleCardClick={() =>
                                                  handleCourseSelected({
                                                      id: value.id,
                                                      courseId: value.course.id,
                                                      title: value.course.title,
                                                      type: 'common',
                                                      isCurious: value.tariff === TARIFF_CURIOUS_TITLE,
                                                      courseGroupTitle: value.title,
                                                      recalculation: value.recalculation,
                                                  })
                                              }
                                              MobileContent={needInsertContent ? MobileContent : undefined}
                                          />
                                      </div>
                                  );
                              })}
                          </div>
                      ),
                      key: course.studentGroup.id,
                  };
              })
            : [];

        const choiceCoursesList = isChoiceExist
            ? [
                  {
                      header: <AppText text={`Курсы по выбору`} fontStyle={'footnotes'} />,
                      content: (
                          <div className={'subjects-table-content__col_wrapper-list'}>
                              {choiceCourses?.map((value) => {
                                  const isCourseSelected = selectedCourse?.id === value.id;
                                  const needInsertContent = !isDesktop && isCourseSelected;

                                  return (
                                      <div
                                          key={value.id}
                                          data-onboarding={isCommonExist ? undefined : 'NewSubjectsCard'}
                                      >
                                          <NewSubjectsCardMemo
                                              title={value.course.title}
                                              additionalTitle={value.title}
                                              progress={value.progress}
                                              borderStyle={'gray'}
                                              isSelected={isCourseSelected}
                                              handleCardClick={() =>
                                                  handleCourseSelected({
                                                      id: value.id,
                                                      courseId: value.course.id,
                                                      title: value.course.title,
                                                      type: 'choice',
                                                      isCurious: value.tariff === TARIFF_CURIOUS_TITLE,
                                                      courseGroupTitle: value.title,
                                                      recalculation: value.recalculation,
                                                  })
                                              }
                                              MobileContent={needInsertContent ? MobileContent : undefined}
                                          />
                                      </div>
                                  );
                              })}
                          </div>
                      ),
                      key: 1001,
                  },
              ]
            : [];

        const additionalCoursesList = isAdditionalExist
            ? [
                  {
                      header: <AppText text={`Дополнительные курсы`} fontStyle={'footnotes'} />,
                      content: (
                          <div className={'subjects-table-content__col_wrapper-list'}>
                              {additionalCourses?.map((value) => {
                                  const isCourseSelected = selectedCourse?.id === value.id;
                                  const needInsertContent = !isDesktop && isCourseSelected;
                                  return (
                                      <div
                                          key={value.id}
                                          data-onboarding={
                                              isCommonExist || isChoiceExist ? undefined : 'NewSubjectsCard'
                                          }
                                      >
                                          <NewSubjectsCardMemo
                                              title={value.course.title}
                                              additionalTitle={value.title}
                                              progress={value.progress}
                                              isSelected={isCourseSelected}
                                              borderStyle={'gray'}
                                              handleCardClick={() =>
                                                  handleCourseSelected({
                                                      id: value.id,
                                                      courseId: value.course.id,
                                                      title: value.course.title,
                                                      type: 'additional',
                                                      isCurious: value.tariff === TARIFF_CURIOUS_TITLE,
                                                      courseGroupTitle: value.title,
                                                      recalculation: value.recalculation,
                                                  })
                                              }
                                              MobileContent={needInsertContent ? MobileContent : undefined}
                                          />
                                      </div>
                                  );
                              })}
                          </div>
                      ),
                      key: 2002,
                  },
              ]
            : [];

        return [...commonCoursesList, ...choiceCoursesList, ...additionalCoursesList];
    };
    // список курсов для учителей
    const getTeachersCourseList = (courseTree: TCourseGroup[], selectedCourse: TSelectedCourse): TCourseListItem[] => {
        return courseTree?.map((courseGroup) => {
            const { id: subjectId, title, courseGroups } = courseGroup;

            return {
                header: <AppText text={title} fontStyle={'footnotes'} />,
                content: (
                    <div className={'subjects-table-content__col_wrapper-list'}>
                        {courseGroups?.map((value) => {
                            const { id, title, studentsCount, course, progress, recalculation } = value;
                            const isCourseSelected = selectedCourse?.id === value.id;
                            const needInsertContent = !isDesktop && isCourseSelected;
                            const additionalTitle = studentsCount
                                ? `${studentsCount} ${getNoun(studentsCount, 'ученик', 'ученика', 'учеников')}`
                                : 'нет учеников';

                            return (
                                <div key={`${id}${selectedCourse.id}`}>
                                    <NewSubjectsCardMemo
                                        title={title}
                                        additionalTitle={additionalTitle}
                                        progress={progress}
                                        borderStyle={'gray'}
                                        isSelected={isCourseSelected}
                                        handleCardClick={() =>
                                            handleCourseSelected({
                                                id: id,
                                                subjectId: subjectId,
                                                courseId: course.id,
                                                title: course.title,
                                                courseGroupTitle: title,
                                                recalculation: recalculation,
                                            })
                                        }
                                        MobileContent={needInsertContent ? MobileContent : undefined}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ),
                key: subjectId,
            };
        });
    };

    const courseList = useMemo(() => {
        if (courseTree) {
            return isStudent
                ? getStudentCourseList(courseTree as TGetCourseTreePayload, selectedCourse)
                : isTeacher
                ? getTeachersCourseList(courseTree as TCourseGroup[], selectedCourse)
                : [];
        }
        return [];
    }, [isStudent, isTeacher, courseTree, isDesktop, selectedCourse, MobileContent]);

    return (
        <div className={'subjects-table-content__col course-col'}>
            <div className={'subjects-table-content__col_wrapper'}>
                {isCourseListLoading ? (
                    <div className={'subjects-table-content__col_wrapper-list'}>
                        {EMPTY_ARRAY_10.map(() => (
                            <SubjectsElementsSkeleton key={getUid()} type={'course-card'} />
                        ))}
                    </div>
                ) : (
                    <div className={'subjects-table-content__col_wrapper-list'}>
                        <AppCollapse
                            key={courseList.map((item) => item.key).join('-')}
                            list={courseList}
                            expandIconPosition={'end'}
                            defaultActiveKey={courseList.map((item) => item.key)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
export const CoursesColContentMemo = React.memo(CoursesColContent);
