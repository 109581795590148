import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { materialsReducer as newMaterials } from '@fsd-entities/materials';
import { navigationReducer as newNavigation } from '@fsd-entities/navigation/model';
import { profileReducer as profile } from '@fsd-entities/profile/models';
import { statisticsMonitoringReducer as statisticsMonitoring } from '@fsd-entities/statistics-monitoring';
import {
    AnyAction,
    CombinedState,
    combineReducers,
    configureStore,
    getDefaultMiddleware,
    ThunkDispatch,
} from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { academicPerformanceReducer as academicPerformance } from 'store/reducers/academicPerformance';
import { academicYearReducer as academicYear } from 'store/reducers/academicYear';
import { activeClassesReducer as activeClasses } from 'store/reducers/activeClasses';
import { adaptationReducer as adaptation } from 'store/reducers/adaptation';
import { adaptationProgressReducer as adaptationProgress } from 'store/reducers/adaptationProgress';
import { alertsReducer as alerts } from 'store/reducers/alerts';
import { answerAttemptsReducer as answerAttempts } from 'store/reducers/answerAttempts';
import { assignmentReducer as assignemnt } from 'store/reducers/assignment';
import { attendanceReducer as attendance } from 'store/reducers/attendance';
import { authBlackList, authReducer as auth } from 'store/reducers/auth';
import { calendarPlanReducer as calendarPlan } from 'store/reducers/calendarPlan';
import { codifierReducer as codifier } from 'store/reducers/codifier';
import { courseReducer as course } from 'store/reducers/course';
import { courseGroupReducer as courseGroup } from 'store/reducers/courseGroup';
import { courseLessonReducer as courseLesson } from 'store/reducers/courseLessons';
import { courseThemeReducer as courseTheme } from 'store/reducers/courseThemes';
import { createTaskReducer as createTask } from 'store/reducers/createTask';
import { curatorDashboardReducer as curatorDashboard } from 'store/reducers/curatorDashboard';
import { eduReducer as edu } from 'store/reducers/edu';
import { educationalPlanReducer as educationalPlan } from 'store/reducers/educationalPlan';
import { errorReducer as error } from 'store/reducers/error';
import { fileReducer as file } from 'store/reducers/file';
import { formErrorReducer as formError } from 'store/reducers/formError';
import { groupActionsReducer as groupActions } from 'store/reducers/groupActions';
import { homeworkReducer as homework } from 'store/reducers/homework';
import { lessonTaskReducer as lessonTask } from 'store/reducers/lessonTask';
import { libraryReducer as library } from 'store/reducers/library';
import { loaderReducer as loader } from 'store/reducers/loader';
import { markReducer as mark } from 'store/reducers/mark';
import { materialsReducer as materials } from 'store/reducers/materials';
import { medicalCertificateReducer as medicalCertificate } from 'store/reducers/medicalCertificate';
import { meetingReducer as meeting } from 'store/reducers/meeting';
import { meetingFilesReducer as meetingFiles } from 'store/reducers/meetingFiles';
import { metaReducer as meta } from 'store/reducers/meta';
import { monitoringReducer as monitoring } from 'store/reducers/monitoring';
import { newsReducer as news } from 'store/reducers/news';
import { NewSubjectsReducer as newSubject } from 'store/reducers/newSubjects';
import { noteReducer as note } from 'store/reducers/notes';
import { notificationsReducer as notifications } from 'store/reducers/notifications';
import { oauthReducer as oauth } from 'store/reducers/oauth';
import { passwordRecoveryReducer as passwordRecovery } from 'store/reducers/passwordRecovery';
import { periodMarkReducer as periodMark } from 'store/reducers/periodMark';
import { proctoringReducer as proctoring } from 'store/reducers/proctoring';
import { questionsReviewRevisionReducer as questionsReviewRevision } from 'store/reducers/question';
import { questionsReducer as questions } from 'store/reducers/questionBank';
import { questionsSearchBlockReducer as searchBlock } from 'store/reducers/questionCreate';
import { revisionQuestionReducer as revisionQuestion } from 'store/reducers/revisionQuestion';
import { scheduleReducer as schedule } from 'store/reducers/schedule';
import { staffProfileReducer as staffProfile } from 'store/reducers/staffProfile';
import { studentReducer as student } from 'store/reducers/student';
import { studentGroupReducer as studentGroup } from 'store/reducers/studentGroups';
import { studentScheduleReducer as studentSchedule } from 'store/reducers/studentSchedule';
import { studentTasksReducer as studentTasks } from 'store/reducers/studentTasks';
import { subjectReducer as subject } from 'store/reducers/subject';
import { userReducer as user } from 'store/reducers/user';

export const reducer = combineReducers({
    assignemnt,
    newMaterials,
    newNavigation,
    meetingFiles,
    alerts,
    attendance,
    profile,
    staffProfile,
    schedule,
    homework,
    subject,
    auth,
    lessonTask,
    file,
    mark,
    materials,
    studentSchedule,
    newSubject,
    loader,
    user,
    meta,
    passwordRecovery,
    course,
    periodMark,
    medicalCertificate,
    academicYear,
    questions,
    monitoring,
    courseTheme,
    courseLesson,
    note,
    formError,
    activeClasses,
    studentTasks,
    codifier,
    createTask,
    searchBlock,
    academicPerformance,
    answerAttempts,
    student,
    edu,
    courseGroup,
    notifications,
    adaptation,
    adaptationProgress,
    questionsReviewRevision,
    meeting,
    educationalPlan,
    revisionQuestion,
    studentGroup,
    curatorDashboard,
    oauth,
    proctoring,
    news,
    library,
    calendarPlan,
    groupActions,
    error,
    statisticsMonitoring,
});

const persistConfig = {
    key: 'root',
    storage,
    transforms: [authBlackList],
    whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, reducer);
export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [],
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
});
export const persistor = persistStore(store);

export type State = ReturnType<typeof reducer>;
export type Action = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): ThunkDispatch<CombinedState<State>, null, AnyAction> => useDispatch<Action>();
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;
