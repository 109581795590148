import { getFieldsError } from '@fsd-entities/profile/utils';
import { IStudentCardResponse } from '@fsd-shared/models/profile/student';
import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'types/api';

import {
    changePasswordAction,
    getStudentCardAction,
    postParentInviteAction,
    updateStudentCardAction,
    updateStudentEmailAction,
    updateStudentPhoneAction,
} from './profile-actions';

export interface IProfileState {
    student?: IStudentCardResponse;
    isCanEdit: boolean;
    isStudentCardLoading?: boolean;
    changePasswordStatus: FetchStatus;
    isEmailUpdating?: boolean;
    isPhoneUpdating?: boolean;
    isInviteSending?: boolean;
    changePasswordErrors?: { [key: string]: string[] };
}

const initialState: IProfileState = {
    isCanEdit: true,
    changePasswordStatus: FetchStatus.INITIAL,
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    /**
     *
     * @param builder
     */
    extraReducers: (builder) => {
        builder
            .addCase(changePasswordAction.pending, (state) => {
                state.changePasswordStatus = FetchStatus.FETCHING;
                state.changePasswordErrors = undefined;
            })
            .addCase(changePasswordAction.fulfilled, (state) => {
                state.changePasswordStatus = FetchStatus.FETCHED;
            })
            .addCase(changePasswordAction.rejected, (state, { error }) => {
                const errorData = getFieldsError(error);
                state.changePasswordErrors = errorData;
                state.changePasswordStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(getStudentCardAction.pending, (state) => {
                state.isStudentCardLoading = true;
            })
            .addCase(getStudentCardAction.fulfilled, (state, { payload }) => {
                state.isStudentCardLoading = false;
                state.isCanEdit = !payload.parents?.length;
                state.student = payload;
            })
            .addCase(getStudentCardAction.rejected, (state, error) => {
                state.isStudentCardLoading = false;
            });
        builder.addCase(updateStudentCardAction.fulfilled, (state, { payload }) => {
            state.student = payload;
        });
        builder
            .addCase(updateStudentEmailAction.pending, (state) => {
                state.isEmailUpdating = true;
            })
            .addCase(updateStudentEmailAction.fulfilled, (state, { payload }) => {
                state.isEmailUpdating = false;
            })
            .addCase(updateStudentEmailAction.rejected, (state, error) => {
                state.isEmailUpdating = false;
            });
        builder
            .addCase(updateStudentPhoneAction.pending, (state) => {
                state.isPhoneUpdating = true;
            })
            .addCase(updateStudentPhoneAction.fulfilled, (state, { payload }) => {
                state.isPhoneUpdating = false;
            })
            .addCase(updateStudentPhoneAction.rejected, (state, error) => {
                state.isPhoneUpdating = false;
            });
        builder
            .addCase(postParentInviteAction.pending, (state) => {
                state.isInviteSending = true;
            })
            .addCase(postParentInviteAction.fulfilled, (state, { payload }) => {
                state.isInviteSending = false;
            })
            .addCase(postParentInviteAction.rejected, (state, error) => {
                state.isInviteSending = false;
            });
    },
});

// export const {} = profileSlice.actions;

export const profileReducer = profileSlice.reducer;
