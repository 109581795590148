import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppText } from '@frontend-modules/ui-kit';
import { StrokeArrowRightIcon } from 'assets';
import { useRole, useWindowWidth } from 'hooks';
import { HardModal } from 'src-new/components/hard-modal';
import {
    getEmptyQuestion,
    getQuestionDetails,
    TaskConfigContainer,
    TNormalizedQuestion,
} from 'src-new/components/lesson-task';
import { TEST_LESSON_ID } from 'src-new/constants';
import { useGetPopupLessonData, useSearchQuestionsPopup, useSubjectsRedirect, useTaskPopup } from 'src-new/hooks';
import { setMessage } from 'src-new/utils/message';
import { getAssignmentTypesAction } from 'store/actions/assignment';
import { getLessonDataAction } from 'store/actions/courseLessons';
import {
    createEmptyTaskQuestionAction,
    createLessonTaskAction,
    deleteTaskQuestionAction,
    getAssignedTasksAction,
    getTaskDataAction,
    updateLessonTaskAction,
} from 'store/actions/lessonTask';
import { resetQuestionsState, setSearchQuestionsIsAllowed } from 'store/reducers/lessonTask';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { ITaskPopup, TNewTaskData, TTaskData } from 'types/lessonTask';

import './style.scss';

const TaskPopup: React.FC<ITaskPopup> = (props) => {
    const { urlProps, ...rest } = props;
    const { lessonId, courseGroupId, taskId, purpose, subjectId } = urlProps;
    const isEditPage = purpose === 'edit';

    const [questionsList, setQuestionsList] = useState<TNormalizedQuestion[]>([]);
    const [selectedTypeId, setSelectedTypeId] = useState<number>(0);
    const [isTaskTemplate, setIsTaskTemplate] = useState<boolean>(false);

    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    const dispatch = useDispatch();
    const TaskPopup = useTaskPopup();
    const { isPrincipal, isSupport, isMethodist, isTeacher } = useRole();
    const isOnlyTeacher = isTeacher && !isMethodist;

    const SearchQuestionsPopup = useSearchQuestionsPopup();

    useSubjectsRedirect({
        modalType: 'TASK',
        urlProps: urlProps,
    });

    const { courseInfo, topicInfo, lessonInfo } = useGetPopupLessonData({
        lessonId,
        courseGroupId,
        taskId,
        isNeedFetch: TaskPopup.isCurrent,
    });
    const isTablet = useWindowWidth(1024);

    const {
        assignmentTypes,
        getTypesStatus,
        taskData,
        assignedTasksList,
        assignedTasksListInitial,
        isSearchQuestionsIsAllowed,
        lessonDetails,
        getStatusLessons,
        createLessonTaskStatus,
        updateLessonTaskStatus,
        getAssignedTasksListStatus,
    } = useAppSelector((store) => ({
        assignmentTypes: store.assignemnt.assignmentTypes,
        getTypesStatus: store.assignemnt.getTypesStatus,
        taskData: store.lessonTask.taskData,
        assignedTasksList: store.lessonTask.assignedTasksList,
        assignedTasksListInitial: store.lessonTask.assignedTasksListInitial,
        lessonDetails: store.lessonTask.lessonDetails,
        getStatusLessons: store.lessonTask.getStatusLessons,
        createLessonTaskStatus: store.lessonTask.createLessonTaskStatus,
        updateLessonTaskStatus: store.lessonTask.updateLessonTaskStatus,
        isSearchQuestionsIsAllowed: store.lessonTask.isSearchQuestionsIsAllowed,
        getAssignedTasksListStatus: store.lessonTask.getAssignedTasksListStatus,
    }));
    const isTasksContainerLoading =
        getStatusLessons === FetchStatus.FETCHING ||
        getTypesStatus === FetchStatus.FETCHING ||
        getAssignedTasksListStatus === FetchStatus.FETCHING;

    const lessonData = lessonDetails[Number(lessonId)] ?? {};
    const questionDetails = getQuestionDetails(assignmentTypes.find(({ id }) => id === selectedTypeId));
    const typeOptionsNormalize = useMemo(() => {
        return assignmentTypes.length
            ? assignmentTypes.map((item) => {
                  return {
                      label: item.title,
                      value: `${item.id}`,
                  };
              })
            : [];
    }, [assignmentTypes]);
    const isQuestionsExist = assignedTasksList
        ? Object.values(assignedTasksList).some(
              (item) => !!Object.keys(item).length && Object.values(item).filter((list) => !!list.length).length,
          )
        : false;
    const isSaveButtonDisabled =
        !isQuestionsExist ||
        !title ||
        !selectedTypeId ||
        !questionsList.filter(({ questionsCount }) => !!questionsCount).length ||
        updateLessonTaskStatus === FetchStatus.FETCHING ||
        createLessonTaskStatus === FetchStatus.FETCHING;
    const isTemplateSwitcherDisabled =
        (!isMethodist && !isPrincipal && !isSupport) || selectedTypeId === TEST_LESSON_ID;

    const breadcrumbsHeader = useMemo(() => {
        return courseInfo?.title && topicInfo?.title ? (
            <div className={'task-popup__breadcrumbs'}>
                <AppText text={courseInfo.title ?? ''} textStyle={'DesktopH4'} color={'#919399'} />
                <StrokeArrowRightIcon width={4} height={8} />
                <AppText text={topicInfo.title ?? ''} textStyle={'DesktopH4'} color={'#919399'} />
                <StrokeArrowRightIcon width={4} height={8} />
                <AppText text={lessonInfo?.title ?? ''} textStyle={'DesktopH4'} />
            </div>
        ) : (
            <></>
        );
    }, [courseInfo, topicInfo]);

    // Получаем данные об уроке (для хлебных крошек)
    useEffect(() => {
        if (getStatusLessons === FetchStatus.INITIAL || Number(lessonId) !== lessonData?.id) {
            dispatch(getLessonDataAction(+lessonId));
        }
    }, [dispatch, lessonId, getStatusLessons, lessonData?.id]);
    useEffect(() => {
        setIsTaskTemplate(!isOnlyTeacher);
    }, [isOnlyTeacher]);
    //Ставим флаг для того, чтобы закрыть модалку поиска вопросов при создании, если страницу обновили
    useEffect(() => {
        if (selectedTypeId && !isSearchQuestionsIsAllowed) {
            dispatch(setSearchQuestionsIsAllowed());
        }
    }, [selectedTypeId, isSearchQuestionsIsAllowed]);
    // Получаем данные о задании и список вопросов
    useEffect(() => {
        if (!!taskId && !assignedTasksList) {
            dispatch(getTaskDataAction(+taskId));
            dispatch(getAssignedTasksAction(+taskId));
        }
    }, [taskId, dispatch, assignedTasksList]);
    // Получаем список возможных типов заданий
    useEffect(() => {
        if (getTypesStatus === FetchStatus.INITIAL) {
            dispatch(getAssignmentTypesAction());
        }
    }, [dispatch, getTypesStatus]);
    // Устанавливаем полученные данные о задании как дефолтные
    useEffect(() => {
        if (taskData) {
            const { isTemplate, type, title, description = '' } = taskData;
            setIsTaskTemplate(isTemplate);
            setSelectedTypeId(type.id);
            setTitle(title);
            setDescription(description);
        }
    }, [taskData]);
    // Нормализуем список вопросов
    useEffect(() => {
        const isListEmpty = assignedTasksList ? !Object.keys(assignedTasksList).length : false;
        if (assignedTasksList && !isListEmpty) {
            const reformattedList: TNormalizedQuestion[] = [];
            Object.values(assignedTasksList).forEach((task, number) => {
                // список вопросов, относящихся к текущему номеру вопроса
                let summaryQuestion = getEmptyQuestion(number + 1);

                const blocksCount = task.searchBlocksList?.length ?? 0;
                const blocksQuestionsCount = blocksCount
                    ? task.searchBlocksList
                          ?.map((block) => {
                              const blockQuestionsIds = block?.questionsContentList
                                  ?.map(({ questionContent }) => questionContent?.id)
                                  .filter(Boolean);
                              const existingExcludedIds = block?.filters?.excludeIds?.filter((item) =>
                                  blockQuestionsIds.includes(item),
                              );
                              const excludeCount = existingExcludedIds?.length ?? 0;

                              const allCount = block?.questionsContentList?.length ?? 0;
                              return allCount - excludeCount;
                          })
                          ?.reduce((total, count) => total + count)
                    : 0;
                const singleCount = task.singleQuestionsList?.length ?? 0;

                if (blocksCount || singleCount) {
                    summaryQuestion = {
                        questionNumber: number + 1,
                        questionsCount: summaryQuestion.questionsCount + singleCount + blocksQuestionsCount,
                        searchBlocksCount: summaryQuestion.searchBlocksCount + blocksCount,
                    };
                }

                reformattedList.push(summaryQuestion);
            });
            setQuestionsList(reformattedList);
        } else {
            setQuestionsList([getEmptyQuestion(1)]);
        }
    }, [assignedTasksList]);

    // Выплевываем тост по выполнении запроса
    useEffect(() => {
        if (createLessonTaskStatus === FetchStatus.FETCHED || updateLessonTaskStatus === FetchStatus.FETCHED) {
            setMessage({
                status: 'success',
                text: 'Задание успешно сохранено!',
                duration: 3,
            });
            TaskPopup.closeLastTaskPopup();
        }
    }, [createLessonTaskStatus, updateLessonTaskStatus]);
    //Чистим стейт при выходе
    useEffect(() => {
        return () => {
            dispatch(resetQuestionsState());
            setTitle('');
            setQuestionsList([]);
            setDescription('');
            setIsTaskTemplate(false);
            setSelectedTypeId(0);
        };
    }, []);

    const onQuestionClick = (num: number) => {
        SearchQuestionsPopup.openSearchQuestionsPopup({
            lessonId,
            subjectId,
            courseGroupId,
            num,
            assignment: taskId,
            purpose: isEditPage ? 'edit' : 'create',
            taskTypeId: selectedTypeId,
        });
    };
    const handleSaveTask = () => {
        if (assignedTasksList && Object.keys(assignedTasksList).length) {
            if (isEditPage && taskData) {
                const taskDataParams: TTaskData = {
                    id: taskData.id,
                    lesson: +lessonId,
                    title,
                };
                if (courseGroupId) {
                    taskDataParams.courseGroup = +courseGroupId;
                }
                if (description !== taskData.description) {
                    taskDataParams.description = description;
                }
                if (isTaskTemplate !== taskData.isTemplate) {
                    taskDataParams.isTemplate = isTaskTemplate;
                }
                if (selectedTypeId !== taskData.type.id) {
                    taskDataParams.type = selectedTypeId;
                }

                dispatch(
                    updateLessonTaskAction({
                        taskData: taskDataParams,
                        assignedQuestions: assignedTasksList,
                        assignedTasksListInitial,
                    }),
                );
            } else {
                const taskDataParams: TNewTaskData = {
                    lesson: +lessonId,
                    title,
                    type: selectedTypeId,
                };
                if (courseGroupId) {
                    taskDataParams.courseGroup = +courseGroupId;
                }
                if (description) {
                    taskDataParams.description = description;
                }
                if (isTaskTemplate) {
                    taskDataParams.isTemplate = isTaskTemplate;
                }

                dispatch(
                    createLessonTaskAction({
                        taskData: taskDataParams,
                        assignedQuestions: assignedTasksList,
                    }),
                );
            }
        }
    };
    const handleChangeQuestionList = (num: number, event: 'add' | 'delete') => {
        if (event === 'delete') {
            dispatch(deleteTaskQuestionAction(num));
        } else {
            dispatch(createEmptyTaskQuestionAction({ questionsNum: num, saveIntoLastVersion: true }));
        }
    };
    const handleCancel = () => {
        TaskPopup.closeLastTaskPopup();
    };
    const handleTypeSelect = (typeId: number) => {
        setSelectedTypeId(typeId);

        if (typeId === TEST_LESSON_ID && !isOnlyTeacher) {
            setIsTaskTemplate(true);
        }
    };

    return (
        <HardModal
            key={'task'}
            className={'task-popup'}
            size={'large'}
            placement={'left'}
            getContainer={false}
            title={breadcrumbsHeader}
            customWrapperStyle={{
                width: '100%',
                maxWidth: isTablet ? '100%' : '65%',
                minWidth: isTablet ? 'unset' : '850px',
            }}
            autoFocus={false}
            {...rest}
        >
            <div className={'task-popup__container'}>
                <TaskConfigContainer
                    title={title}
                    description={description}
                    isTaskTemplate={isTaskTemplate}
                    questionDetails={questionDetails}
                    questionsList={questionsList}
                    typeOptionsNormalize={typeOptionsNormalize}
                    selectedType={typeOptionsNormalize.find(({ value }) => +value === selectedTypeId)}
                    isLoading={isTasksContainerLoading}
                    isSaveButtonDisabled={isSaveButtonDisabled}
                    isSaveButtonLoading={
                        updateLessonTaskStatus === FetchStatus.FETCHING ||
                        createLessonTaskStatus === FetchStatus.FETCHING
                    }
                    isTemplateSwitcherDisabled={isTemplateSwitcherDisabled}
                    handleTaskName={(name) => setTitle(name)}
                    handleTaskDescription={(desc) => setDescription(desc)}
                    handleTypeSelect={handleTypeSelect}
                    handleTemplateSwitcher={(isTemplate) => setIsTaskTemplate(isTemplate)}
                    handleChangeQuestionList={handleChangeQuestionList}
                    handleQuestionClick={onQuestionClick}
                    handleSaveTask={handleSaveTask}
                    handleCancel={handleCancel}
                />
            </div>
        </HardModal>
    );
};
export default TaskPopup;
