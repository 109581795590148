import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SidebarMenu } from '@frontend-modules/elements';
import { useWindowResize } from '@frontend-modules/frontend-utils';
import { useShowSidebar } from '@fsd-entities/navigation/hooks';
import { getNavigationMenuPages, getNavigationOptionsButtons } from '@fsd-entities/navigation/utils';
import { useRole } from 'hooks';
import { getUnreadNewsStatusAction } from 'store/actions/news';
import { useAppDispatch, useAppSelector } from 'store/store';

/**
 * @description меню навигации
 */
export const NavigationMenu: FC = () => {
    const history = useHistory();
    const location = useLocation();
    const { isTablet } = useWindowResize();
    const dispatch = useAppDispatch();
    const { hasUnreadNews, entities } = useAppSelector((store) => ({
        hasUnreadNews: store.news.hasUnreadNews,
        entities: store.meta.entities,
    }));
    const [isOpened, setIsOpened] = useState(true);
    const { userId, ...roles } = useRole();
    const isShowSideBar = useShowSidebar();

    const hasMeta = !!entities && Object.keys(entities).length > 0;
    const navbarItems = useMemo(() => (userId ? getNavigationMenuPages(roles, hasUnreadNews, hasMeta) : []), [
        roles,
        hasUnreadNews,
        userId,
        hasMeta,
    ]);

    const footerButtons = useMemo(() => getNavigationOptionsButtons(roles), [roles]);

    useEffect(() => {
        if (isTablet) {
            setIsOpened(false);
        }
    }, [isTablet]);

    useEffect(() => {
        if (userId) {
            void dispatch(getUnreadNewsStatusAction());
        }
    }, [userId]);

    return isShowSideBar ? (
        <SidebarMenu
            items={navbarItems}
            currentPath={location.pathname}
            onItemClick={(path) => {
                history.push(path);
            }}
            isOpen={isOpened}
            setIsOpen={setIsOpened}
            footerButtons={footerButtons}
            isTablet={isTablet}
            onLogoClick={() => history.push('/')}
        />
    ) : null;
};
