/**
 * ProfileProvider запросы связанные с профилем
 */
import { IChangePasswordRequest } from '@fsd-shared/models/profile/password';
import {
    IParentInviteRequest,
    IParentInviteResponse,
    IStudentCardRequest,
    IStudentCardResponse,
    IStudentContactResponse,
    IStudentEmailRequest,
    IStudentPhoneRequest,
} from '@fsd-shared/models/profile/student';
import { postApiSsoRequest } from 'api';

const ProfileProvider = {
    /**
     * @description Получение и редактирование информации о себе учеником
     * @param params
     */
    postStudentCard: (params?: IStudentCardRequest): Promise<IStudentCardResponse> => {
        return postApiSsoRequest(`sso/v1/student/card/`, params);
    },
    /**
     * @description Редактирование учеником email
     * @param params
     */
    changeMail: (params: IStudentEmailRequest): Promise<IStudentContactResponse> => {
        return postApiSsoRequest(`sso/v1/student/email/`, params);
    },
    /**
     * @description Редактирование учеником телефона
     * @param params
     */
    changePhone: (params?: IStudentPhoneRequest): Promise<IStudentContactResponse> => {
        return postApiSsoRequest(`sso/v1/student/phone/`, params);
    },
    /**
     * @description Отправка ссылки для приглашения родителя на email или телефон
     * @param params
     */
    inviteParent: (params?: IParentInviteRequest): Promise<IParentInviteResponse> => {
        return postApiSsoRequest(`sso/v1/student/invite-parent/`, params);
    },
    /**
     * @description Смена пароля пользователя
     * @param params
     */
    changePassword: (params?: IChangePasswordRequest): Promise<void> => {
        return postApiSsoRequest(`sso/v1/password/change/`, params);
    },
};

export { ProfileProvider };
