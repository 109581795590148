import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ErrorsCaptureWrapperUi } from '@frontend-modules/error-handler';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { TAppCheckboxProps } from './AppCheckbox.types';

import './style.scss';

const AppCheckbox: React.FC<TAppCheckboxProps> = (props: TAppCheckboxProps) => {
    const [check, setCheck] = useState(false);
    const { text, isChecked, isPartiallyChecked, isDisabled, onCheckbox, ...rest } = { ...props };
    const history = useHistory();

    useEffect(() => {
        if (isChecked !== undefined) {
            setCheck(isChecked);
        }
    }, [isChecked]);

    const onChange = (e: CheckboxChangeEvent) => {
        e.stopPropagation();
        setCheck(e.target.checked);
        try {
            void onCheckbox(e.target.checked);
        } catch (err) {
            ErrorsCaptureWrapperUi({
                error: err,
                componentName: 'lms AppCheckbox',
                MethodCatchOn: 'onCheckbox()',
                PathCatchOn: history.location.pathname,
            });
        }
    };

    return (
        <Checkbox
            prefixCls={'app-checkbox'}
            checked={check}
            disabled={isDisabled}
            indeterminate={isPartiallyChecked}
            onChange={onChange}
            {...rest}
        >
            {text}
        </Checkbox>
    );
};

export default AppCheckbox;
