import { createSlice } from '@reduxjs/toolkit';
export interface INavigationState {
    isMobileMenuOpened: boolean;
}

const initialState: INavigationState = {
    isMobileMenuOpened: false,
};

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setIsMobileMenuOpened: (state, { payload }) => {
            state.isMobileMenuOpened = payload;
        },
    },
    /**
     *
     * @param builder
     */
    extraReducers: (builder) => {},
});
export const { setIsMobileMenuOpened } = navigationSlice.actions;

export const navigationReducer = navigationSlice.reducer;
