import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmptyList } from '@frontend-modules/frontend-utils';
import { AppText } from '@frontend-modules/ui-kit';
import { ReturnButton } from '@lms-elements/atomic';
import { StrokeArrowRightIcon } from 'assets';
import { HardModal } from 'src-new/components/hard-modal';
import {
    getEmptyQuestion,
    QuestionSearchBarMemo,
    QuestionSearchResult,
    TNormalizedQuestion,
    TSearchData,
} from 'src-new/components/lesson-task';
import {
    useCreateQuestionPopup,
    useGetPopupLessonData,
    useSearchQuestionsPopup,
    useSubjectsRedirect,
} from 'src-new/hooks';
import { getAssignmentTypesAction } from 'store/actions/assignment';
import {
    createEmptyTaskQuestionAction,
    deleteBlockAction,
    getAssignedTasksAction,
    getQuestionsBySearchAction,
    getTaskDataAction,
    setSelectedBlockAction,
} from 'store/actions/lessonTask';
import { resetToLastVersionState, setLastVersionState } from 'store/reducers/lessonTask';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { ISearchQuestionsPopup, TSelectedBlock } from 'types/lessonTask';

import './style.scss';

const SearchQuestionsPopup: React.FC<ISearchQuestionsPopup> = (props) => {
    const { urlProps, ...rest } = props;
    const { lessonId, courseGroupId, assignment, num = 1, purpose, isTrainer, subjectId, taskTypeId } = urlProps;

    const dispatch = useDispatch();
    const SearchQuestionsPopup = useSearchQuestionsPopup();
    const CreateQuestionPopup = useCreateQuestionPopup();
    const { courseInfo, topicInfo, lessonInfo } = useGetPopupLessonData({
        lessonId,
        courseGroupId,
        taskId: assignment,
        isNeedFetch: SearchQuestionsPopup.isCurrent,
    });
    useSubjectsRedirect({
        modalType: 'SEARCH_QUESTIONS',
        urlProps: urlProps,
    });

    const handleClosePopup = () => {
        dispatch(setLastVersionState());
        dispatch(setSelectedBlockAction(undefined));
        SearchQuestionsPopup.closeLastSearchQuestionsPopup();
    };

    const breadcrumbsHeader = useMemo(() => {
        return courseInfo?.title && topicInfo?.title ? (
            <div className={'search-questions-popup__title'}>
                <div className={'search-questions-popup__title_return-btn'} onClick={() => handleClosePopup()}>
                    <ReturnButton />
                </div>
                <div className={'search-questions-popup__title_breadcrumbs'}>
                    <AppText text={courseInfo.title ?? ''} textStyle={'DesktopH4'} color={'#919399'} />
                    <StrokeArrowRightIcon width={4} height={8} />
                    <AppText text={topicInfo.title ?? ''} textStyle={'DesktopH4'} color={'#919399'} />
                    <StrokeArrowRightIcon width={4} height={8} />
                    <AppText text={lessonInfo?.title ?? ''} textStyle={'DesktopH4'} />
                </div>
            </div>
        ) : (
            <></>
        );
    }, [courseInfo, topicInfo]);

    const [questionsList, setQuestionsList] = useState<TNormalizedQuestion[]>([]);
    const [activeQuestionNumber, setActiveQuestionNumber] = useState<number>(+num);

    const {
        assignmentTypes,
        getTypesStatus,
        isSearchQuestionsIsAllowed,
        assignedTasksList,
        filtersData,
        searchResults,
        getAssignedTasksListStatus,
        getQuestionsBySearchStatus,
    } = useAppSelector((store) => ({
        assignmentTypes: store.assignemnt.assignmentTypes,
        getTypesStatus: store.assignemnt.getTypesStatus,
        isSearchQuestionsIsAllowed: store.lessonTask.isSearchQuestionsIsAllowed,
        filtersData: store.lessonTask.filtersData,
        assignedTasksList: store.lessonTask.assignedTasksList,
        searchResults: store.lessonTask.searchResults,
        getAssignedTasksListStatus: store.lessonTask.getAssignedTasksListStatus,
        getQuestionsBySearchStatus: store.lessonTask.getQuestionsBySearchStatus,
    }));

    const isSearchContainerLoading =
        getAssignedTasksListStatus === FetchStatus.FETCHING || getQuestionsBySearchStatus === FetchStatus.FETCHING;

    const filteredSearchBlock =
        searchResults?.[activeQuestionNumber]?.filter(
            ({ questionNumber }) => questionNumber === activeQuestionNumber,
        ) ?? [];

    const showFiltersData = useMemo(() => {
        return (
            !!filtersData &&
            (!assignedTasksList?.[activeQuestionNumber] ||
                (isEmptyList(assignedTasksList?.[activeQuestionNumber]?.searchBlocksList) &&
                    isEmptyList(assignedTasksList?.[activeQuestionNumber]?.singleQuestionsList)))
        );
    }, [activeQuestionNumber, filtersData, assignedTasksList]);
    const assignmentType = assignmentTypes?.find(({ id }) => id === +taskTypeId);

    // Получаем данные о задании и список вопросов
    useEffect(() => {
        if (!!assignment && !assignedTasksList) {
            dispatch(getTaskDataAction(+assignment));
            dispatch(getAssignedTasksAction(+assignment));
        }
    }, [assignment, dispatch, assignedTasksList]);
    // Получаем список возможных типов заданий
    useEffect(() => {
        if (getTypesStatus === FetchStatus.INITIAL) {
            dispatch(getAssignmentTypesAction());
        }
    }, [dispatch, getTypesStatus]);
    useEffect(() => {
        SearchQuestionsPopup.replaceLastSearchQuestionsPopup({
            lessonId,
            subjectId,
            courseGroupId,
            assignment,
            num: activeQuestionNumber,
            isTrainer,
            purpose,
            taskTypeId,
        });
    }, [activeQuestionNumber]);
    // Нормализуем список вопросов
    useEffect(() => {
        const isListEmpty = assignedTasksList ? !Object.keys(assignedTasksList).length : false;
        if (assignedTasksList && !isListEmpty) {
            const reformattedList: TNormalizedQuestion[] = [];
            Object.values(assignedTasksList).forEach((task, number) => {
                // список вопросов, относящихся к текущему номеру вопроса
                let summaryQuestion = getEmptyQuestion(number + 1);

                const blocksCount = task.searchBlocksList?.length;
                const blocksQuestionsCount = blocksCount
                    ? task.searchBlocksList
                          ?.map((block) => {
                              const blockQuestionsIds = block?.questionsContentList
                                  ?.map(({ questionContent }) => questionContent?.id)
                                  .filter(Boolean);
                              const existingExcludedIds = block?.filters?.excludeIds?.filter((item) =>
                                  blockQuestionsIds.includes(item),
                              );
                              const excludeCount = existingExcludedIds?.length ?? 0;

                              return block?.questionsContentList?.length - excludeCount;
                          })
                          ?.reduce((total, count) => total + count)
                    : 0;
                const singleCount = task.singleQuestionsList?.length;

                if (blocksCount || singleCount) {
                    summaryQuestion = {
                        questionNumber: number + 1,
                        questionsCount: summaryQuestion.questionsCount + singleCount + blocksQuestionsCount,
                        searchBlocksCount: summaryQuestion.searchBlocksCount + blocksCount,
                    };
                }

                reformattedList.push(summaryQuestion);
            });
            const lastQuestionNumber = reformattedList.slice(-1)[0].questionNumber;
            if (activeQuestionNumber > lastQuestionNumber) {
                for (let i = lastQuestionNumber + 1; i <= activeQuestionNumber; i++) {
                    reformattedList.push(getEmptyQuestion(i));
                }
            }
            setQuestionsList(reformattedList);
        } else {
            setQuestionsList([getEmptyQuestion(1)]);
        }
    }, [assignedTasksList]);
    // Закрываем модалку при перезагрузке страницы, если это создание задания
    useEffect(() => {
        if (SearchQuestionsPopup.isCurrent && purpose === 'create' && !isSearchQuestionsIsAllowed) {
            handleClosePopup();
        }
    }, [purpose, isSearchQuestionsIsAllowed, SearchQuestionsPopup.isCurrent]);
    const handleSearchingCancel = () => {
        dispatch(resetToLastVersionState());
        dispatch(setSelectedBlockAction(undefined));
        SearchQuestionsPopup.closeLastSearchQuestionsPopup();
    };
    const handleSearchingSave = () => {
        dispatch(setLastVersionState());
        dispatch(setSelectedBlockAction(undefined));
        SearchQuestionsPopup.closeLastSearchQuestionsPopup();
    };
    const handleCloseModal = () => {
        dispatch(setLastVersionState());
        dispatch(setSelectedBlockAction(undefined));
    };
    const onQuestionClick = (newNum: number) => {
        if (newNum !== activeQuestionNumber) {
            setActiveQuestionNumber(newNum);
            dispatch(setSelectedBlockAction());
        }
    };
    const handleSearchButtonClick = (data: TSearchData, selectedBlock?: TSelectedBlock) => {
        dispatch(
            getQuestionsBySearchAction({
                data,
                assignment: assignment ? +assignment : 0,
                questionNum: activeQuestionNumber,
                selectedBlock,
            }),
        );
    };
    const handleDeleteBlock = (selectedBlock: TSelectedBlock) => {
        dispatch(
            deleteBlockAction({
                questionNum: activeQuestionNumber,
                selectedBlock,
            }),
        );
    };
    const handleCreateNewQuestion = () => {
        CreateQuestionPopup.openCreateQuestionPopup({
            lessonId,
            courseGroupId,
            subjectId,
            isTrainer,
            assignment,
            num,
        });
    };
    const handleChangeQuestionList = (num: number, event: 'add' | 'delete') => {
        if (event === 'add') {
            dispatch(createEmptyTaskQuestionAction({ questionsNum: num }));
            setActiveQuestionNumber(num);
        }
    };

    return (
        <HardModal
            key={'search-questions'}
            className={'search-questions-popup'}
            size={'large'}
            placement={'left'}
            getContainer={false}
            title={breadcrumbsHeader}
            customWrapperStyle={{
                width: '100%',
            }}
            autoFocus={false}
            handleCloseModal={handleCloseModal}
            {...rest}
        >
            <div className={'search-questions-popup__container'}>
                <QuestionSearchBarMemo
                    subjectId={subjectId}
                    isShowEssayOption={assignmentType?.attemptsQuantity === 1}
                    handleSearchButtonClick={handleSearchButtonClick}
                    handleCreateNewQuestion={handleCreateNewQuestion}
                    handleDeleteBlock={handleDeleteBlock}
                    filtersData={showFiltersData ? filtersData : undefined}
                />
                <QuestionSearchResult
                    selectedQuestionsList={assignedTasksList}
                    searchResults={filteredSearchBlock ?? []}
                    questionsList={questionsList}
                    handleQuestionClick={onQuestionClick}
                    activeQuestionNumber={activeQuestionNumber}
                    isLoading={isSearchContainerLoading}
                    handleSearchingCancel={handleSearchingCancel}
                    handleSearchingSave={handleSearchingSave}
                    handleChangeQuestionList={handleChangeQuestionList}
                />
            </div>
        </HardModal>
    );
};

export default SearchQuestionsPopup;
