export const parseQueryString = (queryString: string): Record<string, string> => {
    const queryObj: any = {};
    if (!queryString) return queryObj;

    const queryStr = queryString.substring(1);
    const queryStrSplitted = queryStr.split('&');

    for (let idx = 0; idx < queryStrSplitted.length; idx++) {
        const queryParamEntries = queryStrSplitted[idx].split('=');
        queryObj[queryParamEntries[0]] = decodeURIComponent(decodeURI(queryParamEntries[1]));
    }

    return queryObj;
};
