export const ssoDomainUrl = '/sso/v1';
export const ssoLoginUrl = `${ssoDomainUrl}/token/`;
export const ssoLogoutAllUrl = `${ssoDomainUrl}/token/logout-all/`;
export const ssoLogoutUrl = `${ssoDomainUrl}/token/logout/`;
export const ssoRefreshUrl = `${ssoDomainUrl}/token/refresh/`;
export const ssoVerifyUrl = `${ssoDomainUrl}/token/verify/`;
export const ssoResetPasswordUrl = `${ssoDomainUrl}/password-reset/`;
export const ssoResetPasswordConfirmUrl = `${ssoDomainUrl}/password-reset/confirm/`;
export const ssoResetPasswordValidateUrl = `${ssoDomainUrl}/password-reset/validate-token/`;

export const NO_TOKEN_SSO_PATHS = [
    ssoLoginUrl,
    ssoLogoutAllUrl,
    ssoLogoutUrl,
    ssoRefreshUrl,
    ssoVerifyUrl,
    ssoResetPasswordUrl,
    ssoResetPasswordConfirmUrl,
    ssoResetPasswordValidateUrl,
];
