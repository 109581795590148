import { INavigationPageItem } from '@frontend-modules/elements';
import { INavigationMenuRoles } from '@fsd-shared/models/navigation';
const TEST = true;

export const getNavigationMenuPages = (
    roles: INavigationMenuRoles,
    hasNewNews: boolean,
    hasMeta: boolean,
): Array<INavigationPageItem> => {
    const {
        isTeacher,
        isCurious,
        isMethodist: isMethodistRole,
        isPrincipal: isPrincipalRole,
        isCurator,
        isProctor,
        isTutor,
        isSupport,
        isLibrarian,
        isStudent,
    } = roles;

    // TODO: изменить, когда будут известны все отличия между support и завучем. Сейчас support видит все тоже самое что завуч
    const isPrincipal = isPrincipalRole || isSupport;
    // TODO: изменить, когда будут известны все отличия между методистом и завучем. Сейчас завуч видит все тоже самое что и методист
    const isMethodist = isMethodistRole || isPrincipal;

    const showGroupActions = hasMeta && (isPrincipal || isCurator || isTutor);
    const home = {
        icon: 'home-l',
        text: `Обзор ${isStudent ? 'ученика' : ''}`,
        path: '/',
        pathMatchType: 'exact',
    };
    const news = {
        icon: 'newspaper-l',
        text: 'Новости',
        path: '/news',
        hasBadge: hasNewNews,
    };
    const library = {
        icon: 'book-l',
        text: 'Библиотека',
        path: '/library',
    };
    const subjects = {
        icon: 'files-l',
        text: 'Предметы',
        path: '/subjects',
    };
    //TODO: временная мера, перейти окончательно на newSubjects, когда туда добавятся все фичи
    const oldSubjects = {
        icon: 'files-l',
        text: 'Предметы старые',
        path: '/subjects',
    };
    const newSubjects = {
        icon: 'files-l',
        text: 'Предметы',
        path: '/new-subjects',
    };
    const schedule = {
        icon: 'calendar-l',
        text: 'Расписание',
        path: '/schedule',
    };
    const studentsSchedule = {
        icon: 'calendars-l',
        text: 'Расписание классов',
        path: '/students-schedule',
    };
    const onlineLessons = {
        icon: 'videocamera-l',
        text: 'Онлайн-занятия',
        path: '/online-lessons',
    };
    const monitoring = {
        icon: 'monitoring-l',
        text: 'Мониторинг',
        path: '/monitoring',
    };
    const homework = {
        icon: 'homework-check-l',
        text: 'Домашние задания',
        path: '/homework',
    };
    const performance = {
        icon: 'cup-l',
        text: 'Успеваемость',
        path: '/performance',
    };
    const proctoring = {
        icon: 'proktoring-l',
        text: 'Прокторинг',
        path: '/proctoring',
    };
    const reporting = {
        icon: 'clip-l',
        text: 'Отчетность',
        path: '/reporting',
    };
    const students = {
        icon: 'user-l',
        text: 'Ученики',
        path: '/students',
    };
    const codifier = {
        icon: 'variant-l',
        text: 'Кодификатор',
        path: '/codifier',
    };
    const questions = {
        icon: 'question-in-circle-l',
        text: 'Банк вопросов',
        path: '/questions',
    };
    const groupActions = {
        icon: 'tick-l',
        text: 'Групповые действия',
        path: '/group-actions',
    };

    if (isStudent && isCurious) {
        return [home, newSubjects, library];
    }

    return isCurator === undefined
        ? [home]
        : [
              home,
              news,
              ...((TEST && isCurator) || isTutor
                  ? []
                  : isStudent || (isTeacher && !isMethodist)
                  ? [newSubjects, schedule]
                  : isTeacher && isMethodist
                  ? [oldSubjects, newSubjects, schedule]
                  : [subjects, schedule]),
              ...(isCurator || isTutor || isPrincipal ? [studentsSchedule] : []),
              ...(isPrincipal || isTutor ? [onlineLessons] : []),
              ...(isTeacher || isPrincipal ? [monitoring] : []),
              ...((TEST && isCurator) || isTutor ? [] : [homework]),
              ...(!isCurator ? [performance] : []),
              ...((isProctor && isPrincipal) || isSupport ? [proctoring] : []),
              ...(isPrincipal || isCurator || isTutor ? [reporting] : []),
              ...(showGroupActions ? [groupActions] : []),
              ...(isCurator || isTutor ? [students] : []),
              ...(isLibrarian || !((TEST && isCurator) || isTutor) ? [library] : []),
              ...(isTeacher || isMethodist ? [codifier, questions] : []),
          ];
};
