import React, { Suspense, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { ErrorBoundary } from '@frontend-modules/error-handler';
import { customEventEmitter, useWindowResize } from '@frontend-modules/frontend-utils';
import { AppSizeProvider } from '@frontend-modules/ui-kit';
import { AppSocketNotifications } from '@fsd-app/notification';
import { ErrorModal } from 'containers/ErrorModal';
import { PageLoader } from 'containers/PageLoader';
import { WithChatwoot } from 'containers/WithChatwoot';
import { NavigationMenu } from 'fsd/widgets/navigation';
import { useRole } from 'hooks';
import moment from 'moment';
import { Login } from 'pages/Login';
import { PasswordRecovery } from 'pages/PasswordRecovery';
import HttpInterceptor from 'src-new/components/auth/HttpInterceptor';
import { Modals } from 'src-new/components/layouts';
import { AlertModal } from 'src-new/containers/AlertModal';
import { getUid, parseQueryString } from 'src-new/utils';
import { getAlertsListAction } from 'store/actions/alerts';
import { setIsLoggedInAction } from 'store/actions/auth';
import { useAppSelector } from 'store/store';

import { PrivateRoute } from 'components/PrivateRoute';
import { getAccessToken, setAccessToken, setRefreshToken } from 'utils/token';

import 'moment-timezone';
import 'moment/locale/ru';
import 'i18n';

import { reCaptchaKey } from './constants';

import 'react-quill/dist/quill.snow.css';
import '@frontend-modules/error-handler/assets/styles/error-handler-styles.scss';
import 'root.scss';
import './App.scss';

moment.locale('ru');
moment.tz.load({
    version: '2021e',
    zones: [
        'Europe/Moscow|MMT MMT MST MDST MSD MSK +05 EET EEST MSK|-2u.h -2v.j -3v.j -4v.j -40 -30 -50 -20 -30 -40|012132345464575454545454545454545458754545454545454545454545454545454545454595|-2ag2u.h 2pyW.W 1bA0 11X0 GN0 1Hb0 c4v.j ik0 3DA0 dz0 15A0 c10 2q10 iM10 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cN0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0|16e6',
    ],
    links: ['Europe/Moscow|W-SU'],
});
moment.tz.setDefault('Europe/Moscow');

const CnApp = cn('app-page');

const StudentsSchedulePage = React.lazy(() => import('pages/StudentsSchedule'));
const QuestionBankPage = React.lazy(() => import('pages/QuestionBankPage'));
const QuestionCreationPage = React.lazy(() => import('pages/QuestionCreationPage'));
const QuestionSearchPage = React.lazy(() => import('pages/QuestionSearchPage'));
const TrainingQuestionSearchPage = React.lazy(() => import('pages/QuestionSearchPage/TrainingQuestionSearchPage'));
const SchedulePage = React.lazy(() => import('pages/SchedulePage'));
const NewSubjectsPage = React.lazy(() => import('pages/NewSubjects'));
const Settings = React.lazy(() => import('pages/Settings'));
const StudentTasks = React.lazy(() => import('pages/StudentTasks'));
const SubjectCoursePage = React.lazy(() => import('pages/SubjectCourse'));
const Subjects = React.lazy(() => import('pages/Subjects'));
const HomeworkPage = React.lazy(() => import('pages/HomeworkPage'));
const LessonCard = React.lazy(() => import('pages/LessonCard'));
const MaterialsCreationPage = React.lazy(() => import('pages/MaterialsCreationPage'));
const Person = React.lazy(() => import('pages/Person'));
const AcademicPerformance = React.lazy(() => import('pages/AcademicPerformance'));
const CodifierPage = React.lazy(() => import('pages/CodifierPage'));
const Proctoring = React.lazy(() => import('pages/ProctoringPage'));
const ProctoringRoom = React.lazy(() => import('pages/ProctoringRoom'));
const Course = React.lazy(() => import('pages/Course'));
const CourseChangingPage = React.lazy(() => import('pages/CourseChangingPage'));
const CourseCreationPage = React.lazy(() => import('pages/CourseCreationPage'));
const CourseEditor = React.lazy(() => import('pages/CourseEditor'));
const Courses = React.lazy(() => import('pages/Courses'));
const StudentsPage = React.lazy(() => import('pages/Students'));
const EventRoomCreationPage = React.lazy(() => import('pages/EventRoomCreationPage'));
const ReportingPage = React.lazy(() => import('pages/ReportingPage'));
const SingleNewsPage = React.lazy(() => import('pages/SingleNewsPage'));
const DownloadAnyDeskPage = React.lazy(() => import('@fsd-pages/DownloadAnyDesk'));
const Dashboard = React.lazy(() => import('pages/Dashboard'));
const AuthenticationPage = React.lazy(() => import('pages/OAuth'));
const OnlineLessonsPage = React.lazy(() => import('pages/OnlineLessonsPage'));
const StatisticsMonitoring = React.lazy(() => import('@fsd-pages/StatisticsMonitoring'));
const LibraryPage = React.lazy(() => import('pages/LibraryPage'));
const EditLibraryPage = React.lazy(() => import('pages/EditLibraryPage'));
const NewsPage = React.lazy(() => import('pages/NewsPage'));
const NewsCreationPage = React.lazy(() => import('pages/NewsCreationPage'));
const PDFViewer = React.lazy(() => import('pages/PDFViewer'));
const GroupActionsPage = React.lazy(() => import('pages/GroupActionsPage'));

export const App: React.FC = () => {
    const dispatch = useDispatch();
    const { isMobile } = useWindowResize();
    const { isLoggedIn } = useAppSelector((state) => state.auth);
    const intervalId = useRef<any>(null);
    const [isReAuth, setReAuth] = useState(false);

    const getAlerts = () => {
        dispatch(getAlertsListAction());
    };
    const { isStudent } = useRole();
    const clearAlertInterval = () => {
        clearInterval(intervalId.current);
    };

    // отключаем алерты при перелогине
    const onReLoginStart = () => {
        setReAuth(true);
        clearAlertInterval?.();
    };

    useEffect(() => {
        setReAuth(!isLoggedIn);
        if (isLoggedIn) {
            getAlerts();
            intervalId.current = setInterval(() => getAlerts(), 60 * 1000);
        }

        return () => {
            clearAlertInterval();
        };
    }, [isLoggedIn]);

    // нужно для авторизации через login as
    useEffect(() => {
        const { access, refresh } = parseQueryString(window.location.search);
        if (access) {
            setAccessToken(access);
            setRefreshToken(refresh ?? access);
            window.open('/', '_self');
        } else {
            const oldAccess = getAccessToken();
            if (oldAccess && !isLoggedIn) {
                dispatch(setIsLoggedInAction(true));
            }
        }
    }, []);

    useEffect(() => {
        const observer = customEventEmitter.Instance;
        const changeFavicon = (data: string) => {
            const icoFavicon = document.getElementById('ico-favicon') as HTMLLinkElement;
            const svgFavicon = document.getElementById('svg-favicon') as HTMLLinkElement;
            const ico = `${data}_favicon.ico`;
            const svg = `${data}_favicon.svg`;
            if (icoFavicon) icoFavicon.href = ico;
            if (svgFavicon) svgFavicon.href = svg;
        };

        void observer.listen('chat_unread_messages', changeFavicon);

        return () => {
            void observer.removeListen('chat_unread_messages');
        };
    }, []);

    return (
        <DndProvider backend={HTML5Backend}>
            <HttpInterceptor onReLoginStart={onReLoginStart} />
            <AppSocketNotifications />
            <WithChatwoot>
                <AppSizeProvider>
                    <Router>
                        {process.env.REACT_APP_IS_DEV === 'dev' && (
                            <a href={'/storybook/index.html'} target="_blank" rel="noreferrer">
                                <div className="storybook-logo" />
                            </a>
                        )}
                        {/*isReAuth нужен, что бы не показывать ошибки при перелогине */}
                        {!isReAuth && <ErrorModal />}
                        <AlertModal />
                        <div className={CnApp('container', { isMobile })} data-onboarding={'privateLayout'}>
                            <ErrorBoundary
                                key={`NavigationMenu-element`}
                                errorMessage="NavigationMenu has crashed"
                                showAlertReportBtn={!isStudent}
                            >
                                <NavigationMenu />
                            </ErrorBoundary>
                            <div className={CnApp('content', { isMobile })}>
                                <Modals />
                                <Switch>
                                    <PrivateRoute path="/group-actions" isExact>
                                        <ErrorBoundary
                                            key={`/group-actions`}
                                            errorMessage="GroupActionsPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <GroupActionsPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute path="/news/edit/:newsId" isExact>
                                        <ErrorBoundary
                                            key={`/news/edit/:newsId`}
                                            errorMessage="NewsCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <NewsCreationPage isEditPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute path="/news/create" isExact>
                                        <ErrorBoundary
                                            key={`/news/create`}
                                            errorMessage="NewsCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <NewsCreationPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute path="/news/:newsId">
                                        <ErrorBoundary
                                            key={`/news/:newsId`}
                                            errorMessage="SingleNewsPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <SingleNewsPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute path="/news">
                                        <ErrorBoundary
                                            key={`/news`}
                                            errorMessage="NewsPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <NewsPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute path="/library/book-view/*" isExact>
                                        <ErrorBoundary
                                            key={`/library/book-view/*`}
                                            errorMessage="PDFViewer has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <PDFViewer />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute path="/library/create" isExact>
                                        <ErrorBoundary
                                            key={`/library/create`}
                                            errorMessage="EditLibraryPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <EditLibraryPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute path="/library/edit/:id" isExact>
                                        <ErrorBoundary
                                            key={`/library/edit/:id`}
                                            errorMessage="EditLibraryPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <EditLibraryPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute path="/library" isExact>
                                        <ErrorBoundary
                                            key={`/library`}
                                            errorMessage="LibraryPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <LibraryPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute path="/proctoring/edit/:roomId">
                                        <ErrorBoundary
                                            key={`/proctoring/edit/:roomId`}
                                            errorMessage="EventRoomCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <EventRoomCreationPage isEditPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute path="/proctoring/create">
                                        <ErrorBoundary
                                            key={`/proctoring/create`}
                                            errorMessage="EventRoomCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <EventRoomCreationPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/proctoring/event-room/:roomId">
                                        <ErrorBoundary
                                            key={`/proctoring/event-room/:roomId`}
                                            errorMessage="ProctoringRoom has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <ProctoringRoom />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact
                                        path={[
                                            '/reporting/:reportingType/:mode/:id',
                                            '/reporting/:reportingType/:mode',
                                            '/reporting/:reportingType',
                                            '/reporting',
                                        ]}
                                    >
                                        <ErrorBoundary
                                            key={`/reporting/...`}
                                            errorMessage="ReportingPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <ReportingPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/students">
                                        <ErrorBoundary
                                            key={`/students`}
                                            errorMessage="StudentsPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <StudentsPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/settings">
                                        <ErrorBoundary
                                            key={`/settings`}
                                            errorMessage="Settings has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <Settings />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/homework">
                                        <ErrorBoundary
                                            key={`/homework`}
                                            errorMessage="HomeworkPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <HomeworkPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/students-schedule">
                                        <ErrorBoundary
                                            key={`/students-schedule`}
                                            errorMessage="StudentsSchedulePage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <StudentsSchedulePage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/online-lessons">
                                        <ErrorBoundary
                                            key={`/online-lessons`}
                                            errorMessage="OnlineLessonsPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <OnlineLessonsPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path={['/monitoring/:teacherId', '/monitoring']}>
                                        <ErrorBoundary
                                            key={`/monitoring`}
                                            errorMessage="StatisticsMonitoring has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <StatisticsMonitoring />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/schedule">
                                        <ErrorBoundary
                                            key={`/schedule`}
                                            errorMessage="SchedulePage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <SchedulePage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/courses/:courseId/course-editor"
                                    >
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/courses/:courseId/course-editor`}
                                            errorMessage="CourseEditor has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <CourseEditor />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/materials/:editOrCreate/question-edit/:questionId"
                                    >
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/:lessonId/materials/:editOrCreate/question-edit/:questionId`}
                                            errorMessage="QuestionCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <QuestionCreationPage isFromMaterials isEditPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/materials/:editOrCreate/question-create"
                                    >
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/:lessonId/materials/:editOrCreate/question-create`}
                                            errorMessage="QuestionCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <QuestionCreationPage isFromMaterials />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/materials/:editOrCreate/fill-trainer/:materialIndex/question-create"
                                    >
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/:lessonId/materials/:editOrCreate/fill-trainer/:materialIndex/question-create`}
                                            errorMessage="QuestionCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <QuestionCreationPage isFillTrainer />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path={[
                                            '/subjects/:subjectId/:lessonId/task/search-questions/:questionNum/create',
                                            '/subjects/:subjectId/:lessonId/task/edit/search-questions/:questionNum/:assignmentId/create',
                                        ]}
                                    >
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/:lessonId/task/search-questions/:questionNum/...`}
                                            errorMessage="QuestionCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <QuestionCreationPage isSearchPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/task/search-questions/fill-trainer/:trainerId/create"
                                    >
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/:lessonId/task/search-questions/fill-trainer/:trainerId/create`}
                                            errorMessage="TrainingQuestionSearchPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <TrainingQuestionSearchPage isFillTrainer />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/task/search-questions/fill-trainer/:trainerId/edit"
                                    >
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/:lessonId/task/search-questions/fill-trainer/:trainerId/edit`}
                                            errorMessage="TrainingQuestionSearchPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <TrainingQuestionSearchPage isFillTrainer isEditPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/task/search-questions/:questionNum"
                                    >
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/:lessonId/task/search-questions/:questionNum`}
                                            errorMessage="QuestionSearchPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <QuestionSearchPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/task/edit/search-questions/:questionNum/:assignmentId"
                                    >
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/:lessonId/task/edit/search-questions/:questionNum/:assignmentId`}
                                            errorMessage="QuestionSearchPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <QuestionSearchPage isEditPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/subjects/:subjectId/:lessonId/tasks/create">
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/:lessonId/tasks/create`}
                                            errorMessage="SubjectCoursePage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <SubjectCoursePage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/tasks/edit/:assignmentId"
                                    >
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/:lessonId/tasks/edit/:assignmentId`}
                                            errorMessage="SubjectCoursePage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <SubjectCoursePage isEditPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/subjects/:subjectId/courses/create-course">
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/courses/create-course`}
                                            errorMessage="CourseCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <CourseCreationPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/subjects/:subjectId/courses/edit-courses">
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/courses/edit-courses`}
                                            errorMessage="CourseChangingPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <CourseChangingPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/subjects/:subjectId/courses/:courseId">
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/courses/:courseId`}
                                            errorMessage="Course has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <Course />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/anydesk">
                                        <Suspense fallback={<PageLoader />}>
                                            <DownloadAnyDeskPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path={[
                                            '/event-room/:roomId/lessons/:lessonId/tasks/students/:studentId/:taskId',
                                            '/subjects/:subjectId/lessons/:lessonId/tasks/students/:studentId/:taskId',
                                            '/subjects/:subjectId/lessons/:lessonId/tasks/students/:studentId',
                                        ]}
                                    >
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/lessons/:lessonId/tasks/students/:studentId`}
                                            errorMessage="StudentTasks has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <StudentTasks />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/subjects/:subjectId/courses">
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/courses`}
                                            errorMessage="Courses has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <Courses />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/subjects/:subjectId/:lessonId/materials/edit">
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/:lessonId/materials/edit`}
                                            errorMessage="MaterialsCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <MaterialsCreationPage isEditPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/materials/create"
                                    >
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/:lessonId/materials/create`}
                                            errorMessage="MaterialsCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <MaterialsCreationPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/additional_materials/edit"
                                    >
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/:lessonId/additional_materials/edit`}
                                            errorMessage="MaterialsCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <MaterialsCreationPage isEditPage isAdditional />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/additional_materials/create"
                                    >
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/:lessonId/additional_materials/create`}
                                            errorMessage="MaterialsCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <MaterialsCreationPage isAdditional />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>

                                    <PrivateRoute isExact={false} path="/subjects/:subjectId/:lessonId">
                                        <ErrorBoundary
                                            key={`/subjects/:subjectId/:lessonId`}
                                            errorMessage="LessonCard has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <LessonCard />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path={['/profile/:role/:personId/:tab', '/profile/:role/:personId']}
                                    >
                                        <ErrorBoundary
                                            key={`/profile/:role/:personId/`}
                                            errorMessage="Person has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <Person />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/codifier/create-question/:lessonId">
                                        <ErrorBoundary
                                            key={`/codifier/create-question/:lessonId`}
                                            errorMessage="QuestionCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <QuestionCreationPage isFromCodifier />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/codifier">
                                        <ErrorBoundary
                                            key={`/codifier`}
                                            errorMessage="CodifierPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <CodifierPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/questions/edit/:questionId">
                                        <ErrorBoundary
                                            key={`/questions/edit/:questionId`}
                                            errorMessage="QuestionCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <QuestionCreationPage isEditPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/questions/create">
                                        <ErrorBoundary
                                            key={`/questions/create`}
                                            errorMessage="QuestionCreationPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <QuestionCreationPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/questions/select">
                                        <ErrorBoundary
                                            key={`/questions/select`}
                                            errorMessage="QuestionBankPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <QuestionBankPage isSelectQuestion />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/questions">
                                        <ErrorBoundary
                                            key={`/questions`}
                                            errorMessage="QuestionBankPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <QuestionBankPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/subjects">
                                        <ErrorBoundary
                                            key={`/subjects`}
                                            errorMessage="Suspense has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <Subjects />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/performance">
                                        <ErrorBoundary
                                            key={`/performance`}
                                            errorMessage="AcademicPerformance has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <AcademicPerformance />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/proctoring">
                                        <ErrorBoundary
                                            key={`/proctoring`}
                                            errorMessage="Proctoring has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <Proctoring />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <Route exact path="/signin">
                                        <Suspense fallback={<PageLoader />}>
                                            <Login />
                                        </Suspense>
                                    </Route>
                                    <Route exact path="/restore">
                                        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} language="ru">
                                            <PasswordRecovery />
                                        </GoogleReCaptchaProvider>
                                    </Route>
                                    <PrivateRoute isExact path="/o/authentication">
                                        <Suspense fallback={<PageLoader />}>
                                            <AuthenticationPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/new-subjects">
                                        <ErrorBoundary
                                            key={`/new-subjects`}
                                            errorMessage="NewSubjectsPage has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <NewSubjectsPage />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/">
                                        <ErrorBoundary
                                            key={`Dashboard page`}
                                            errorMessage="Dashboard has crashed"
                                            showAlertReportBtn={!isStudent}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <PageLoader />
                                                <Dashboard />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </PrivateRoute>
                                </Switch>
                            </div>
                        </div>
                    </Router>
                </AppSizeProvider>
            </WithChatwoot>
        </DndProvider>
    );
};
