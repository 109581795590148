import { socketHost } from 'constants/apiMethods';

import { io, Socket } from 'socket.io-client';

export const createSocket = (path: string, query: { token: string }): Socket => {
    return io(socketHost, {
        path: path,
        transports: ['websocket'],
        reconnection: false,
        query: query,
    });
};
export { Socket };
