import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
    NewSubjectsCardMemo,
    SubjectsElementsSkeleton,
    ThemesColTabs,
    TTopicsColContent,
} from 'src-new/components/new-subjects';
import { EMPTY_ARRAY_1, EMPTY_ARRAY_2, EMPTY_ARRAY_10 } from 'src-new/constants';
import { AppText } from 'src-new/ui';
import { getUid } from 'src-new/utils';
import { getStudentsListAction } from 'store/actions/newSubjects';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';

export const TopicsColContent: React.FC<TTopicsColContent> = (props) => {
    const dispatch = useDispatch();
    const topicTarget = useRef<HTMLDivElement>(null);

    const {
        isTopicListLoading,
        isCurious,
        hasBooks,
        isDesktop,
        courseTeachers,
        LessonsColContent,
        handleTeacherClick,
        handleStudentClick,
        handleLibraryClick,
        handleTopicSelect,
    } = props;

    const {
        selectedCourse,
        topicsTree,
        selectedTopicId,
        getTopicsTreeStatus,
        studentsList,
        studentsListPaginate,
        getStudentsListStatus,
    } = useAppSelector((store) => store.newSubject);

    const isTopicListEmpty = getTopicsTreeStatus === FetchStatus.FETCHED && !topicsTree.length;
    const hasPersonsList = !!courseTeachers?.length || !!studentsList?.length;

    const targetTopicId = selectedTopicId || topicsTree[0]?.id;

    // Скролл до нужных темы и урока в списках
    useEffect(() => {
        if (topicTarget.current && !isTopicListLoading) {
            topicTarget.current.scrollIntoView({ block: 'nearest', behavior: 'auto' });
        }
    }, [isTopicListLoading, topicTarget]);

    const handleStudentsListPaginate = (currPage: number) => {
        dispatch(
            getStudentsListAction({
                courseGroup: selectedCourse.id,
                currentPage: currPage,
            }),
        );
    };
    return (
        <div className={'subjects-table-content__col theme-col'}>
            {isTopicListLoading ? (
                <>
                    <div className={'subjects-table-content__col_header'}>
                        <SubjectsElementsSkeleton type={'title'} />
                    </div>
                    {!isCurious && (hasBooks || hasPersonsList) && (
                        <div className={'tabs-loading-container'} style={{ gap: '8px' }}>
                            {(hasPersonsList ? EMPTY_ARRAY_2 : EMPTY_ARRAY_1).map(() => (
                                <SubjectsElementsSkeleton key={getUid()} type={'button'} isInverseBackground />
                            ))}
                        </div>
                    )}

                    <div className={'subjects-table-content__col_wrapper'}>
                        <div className={'subjects-table-content__col_wrapper-list'}>
                            {EMPTY_ARRAY_10.map(() => (
                                <SubjectsElementsSkeleton key={getUid()} type={'course-card'} isInverseBackground />
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={'subjects-table-content__col_header'}>
                        <AppText text={'Темы'} fontStyle={'H2'} />
                    </div>
                    {!isCurious && (hasBooks || hasPersonsList) && (
                        <ThemesColTabs
                            hasBooks={hasBooks}
                            students={studentsList}
                            paginate={studentsListPaginate}
                            isStudentListLoading={getStudentsListStatus === FetchStatus.FETCHING}
                            teachers={courseTeachers}
                            handleTeacherClick={handleTeacherClick}
                            handleStudentClick={handleStudentClick}
                            handleLibraryClick={handleLibraryClick}
                            handleStudentsListPaginate={handleStudentsListPaginate}
                        />
                    )}
                    <div className={'subjects-table-content__col_wrapper'}>
                        {!isTopicListEmpty ? (
                            <div className={'subjects-table-content__col_wrapper-list'}>
                                {topicsTree?.map((value) => {
                                    const isTopicSelected = targetTopicId === value.id;
                                    const needInsertContent = !isDesktop && isTopicSelected;

                                    return (
                                        <div
                                            key={value.id}
                                            ref={targetTopicId === value.id ? topicTarget : null}
                                            data-onboarding="NewSubjectsCard"
                                        >
                                            <NewSubjectsCardMemo
                                                title={value.title}
                                                progress={value.progress}
                                                backgroundStyle={'dark'}
                                                borderStyle={'gray'}
                                                hasHoverTrigger={isDesktop}
                                                isSelected={isTopicSelected}
                                                MobileContent={needInsertContent ? LessonsColContent : undefined}
                                                handleCardClick={() =>
                                                    handleTopicSelect({
                                                        courseGroup: selectedCourse.id,
                                                        topic: value.id,
                                                    })
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className={'subjects-table-content__col_wrapper-empty'}>
                                <AppText text={'Нет ни одной темы'} fontStyle={'H3'} />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
export const TopicsColContentMemo = React.memo(TopicsColContent);
