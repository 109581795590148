import { commonHost, host, monitoringHost, ssoHost } from 'constants/apiMethods';

import { HttpClient } from '@frontend-modules/form-generator';
import { OnBoardingHttpClient } from '@frontend-modules/og-onboarding';
import { ApiInterceptor, ApiResponder, createInstance } from '@fsd-shared/utils';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { NO_TOKEN_SSO_PATHS, ssoRefreshUrl } from 'src-new/constants/authUrl';

import { getAccessToken } from 'utils/token';

export const api = createInstance(host);
export const apiSso = createInstance(ssoHost);
export const apiMonitoring = createInstance(monitoringHost);
export const apiCommon = createInstance(commonHost);

// @ts-ignore
HttpClient.setClient(api);
// @ts-ignore
HttpClient.setClient(apiCommon, 'file-manager');
OnBoardingHttpClient.setClient(apiCommon);

ApiInterceptor(api);
ApiInterceptor(apiMonitoring);
ApiInterceptor(apiCommon);

export const authStateResponder = (callback: Function, on403Logout: Function, showErrorReportBtn?: boolean): void => {
    ApiResponder(api, callback, on403Logout, showErrorReportBtn);
    ApiResponder(apiMonitoring, callback, on403Logout, showErrorReportBtn);
    ApiResponder(apiCommon, callback, on403Logout, showErrorReportBtn);
};

apiSso.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const token = getAccessToken();
        const isNoNeedToken = NO_TOKEN_SSO_PATHS.some((item) => (config.url ? item?.includes(config.url) : false));
        if (token && !isNoNeedToken) {
            (config.headers as Record<string, string>)['Authorization'] = `Bearer ${token}`;
        }
        config.withCredentials = true;

        return config;
    },
    (error) => {
        Promise.reject(error).catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
    },
);

export const authSsoStateResponder = (callback?: () => void): void => {
    apiSso.interceptors.response.use(
        (response) => {
            return response;
        },
        (error: AxiosError<never>) => {
            const originalRequest = error.config as AxiosRequestConfig & { _retry: boolean };
            const responseStatus = error?.response?.status;

            if ((responseStatus === 401 || responseStatus === 400) && originalRequest.url?.includes(ssoRefreshUrl)) {
                callback?.();
            }

            return Promise.reject(error).catch((error: AxiosError) => {
                throw error;
            });
        },
    );
};
export const postApiSsoRequest = <ResponseType, BodyType>(
    link: string,
    body?: BodyType,
    config?: AxiosRequestConfig,
): Promise<ResponseType> =>
    apiSso
        .post<ResponseType>(link, body, config)
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
export const getApiSsoRequest = <ResponseType, BodyType>(link: string, body?: BodyType): Promise<ResponseType> =>
    apiSso
        .get<ResponseType>(link, body)
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });

export const getApiRequest = <ResponseType, BodyType>(link: string, body?: BodyType): Promise<ResponseType> =>
    api
        .get<ResponseType>(link, body)
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });

export const patchApiRequest = <ResponseType, BodyType>(
    link: string,
    body?: BodyType,
    config?: AxiosRequestConfig,
): Promise<ResponseType> =>
    api
        .patch<ResponseType>(link, body, config)
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });

export const postApiRequest = <ResponseType, BodyType>(
    link: string,
    body?: BodyType,
    config?: AxiosRequestConfig,
): Promise<ResponseType> =>
    api
        .post<ResponseType>(link, body, config)
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });

export const deleteApiRequest = <ResponseType, BodyType>(link: string, params?: BodyType): Promise<ResponseType> =>
    api
        .delete<ResponseType>(link, params)
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });

/**
 *
 * @param config
 */
export const universalApiRequest = async <ResponseType>(config: AxiosRequestConfig): Promise<ResponseType> => {
    return await api
        .request<ResponseType>(config)
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};
