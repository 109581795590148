import React from 'react';
import { useHistory } from 'react-router-dom';
import { ErrorsCaptureWrapperUi } from '@frontend-modules/error-handler';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Space } from 'antd';
import { SmallArrowIcon } from 'assets';
import { AppText } from 'src-new/ui';

import { TAppButtonDropdownProps } from './AppButtonDropdown.types';

import './style.scss';

export const AppButtonDropdown: React.FC<TAppButtonDropdownProps> = (params: TAppButtonDropdownProps) => {
    const {
        buttonText,
        options,
        size = 'large',
        type = 'default',
        disabled = false,
        trigger = ['click'],
        menuPlacement = 'bottomLeft',
        DropdownContainer,
        onDropdownSelect,
    } = params;

    const history = useHistory();

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        try {
            onDropdownSelect(+e.key);
        } catch (err) {
            ErrorsCaptureWrapperUi({
                error: err,
                componentName: 'lms AppButtonDropdown',
                MethodCatchOn: 'onDropdownSelect()',
                PathCatchOn: history.location.pathname,
            });
        }
    };

    const menuProps = {
        items: options,
        onClick: handleMenuClick,
    };

    return (
        <Dropdown
            prefixCls={'app-button-dropdown'}
            menu={menuProps}
            trigger={trigger}
            placement={menuPlacement}
            disabled={disabled}
            dropdownRender={(menu) =>
                DropdownContainer ? (
                    DropdownContainer(React.cloneElement(menu as React.ReactElement))
                ) : (
                    <div className={'app-button-dropdown__container'}>
                        <div className={'app-button-dropdown__container_list'}>
                            {React.cloneElement(menu as React.ReactElement)}
                        </div>
                    </div>
                )
            }
        >
            <Button prefixCls={'app-button-dropdown__btn'} type={type} size={size}>
                <Space prefixCls={'app-button-dropdown__btn_space'}>
                    <AppText text={buttonText} fontStyle={'H4'} />
                    <SmallArrowIcon />
                </Space>
            </Button>
        </Dropdown>
    );
};
