import { currentEnvironment, dsn, isLocalHost } from 'constants/apiMethods';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { App } from 'App';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store/store';

import reportWebVitals from './reportWebVitals';

import './root.scss';
import './index.scss';

if (process.env.REACT_APP_IS_DEV !== 'dev' && !isLocalHost) {
    Sentry.init({
        dsn,
        tracesSampleRate: 1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.Replay({
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
            }),
        ],
        environment: currentEnvironment,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
