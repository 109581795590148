import { IChangePasswordRequest } from '@fsd-shared/models/profile/password';
import {
    IParentInviteRequest,
    IStudentCardRequest,
    IStudentEmailRequest,
    IStudentPhoneRequest,
} from '@fsd-shared/models/profile/student';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProfileProvider } from './profile-api';

export const getStudentCardAction = createAsyncThunk('profile/getStudentCardAction', async () => {
    return await ProfileProvider.postStudentCard();
});

export const updateStudentCardAction = createAsyncThunk(
    'profile/updateStudentCardAction',
    async (params: IStudentCardRequest) => {
        return await ProfileProvider.postStudentCard(params);
    },
);

export const updateStudentEmailAction = createAsyncThunk(
    'profile/updateStudentEmailAction',
    async (params: IStudentEmailRequest) => {
        return await ProfileProvider.changeMail(params);
    },
);

export const updateStudentPhoneAction = createAsyncThunk(
    'profile/updateStudentPhoneAction',
    async (params: IStudentPhoneRequest) => {
        return await ProfileProvider.changePhone(params);
    },
);

export const postParentInviteAction = createAsyncThunk(
    'profile/postParentInviteAction',
    async (params: IParentInviteRequest) => {
        return await ProfileProvider.inviteParent(params);
    },
);

export const changePasswordAction = createAsyncThunk(
    'profile/changePasswordAction',
    async (params: IChangePasswordRequest) => {
        return await ProfileProvider.changePassword(params);
    },
);
